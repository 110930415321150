import styled, { css } from 'styled-components'

interface RadioGroupStProps {
  inline?: boolean
  hasError: boolean
}

const RadioGroupSt = styled.div<RadioGroupStProps>`
  margin: -1rem 0;
  
  > div {
    margin: 1rem 0;
  }
  
  ${({ inline }) => inline && css`
    display: flex;
    justify-content: space-between;
    margin: 0 -1rem;

    label {
      margin: 0 1rem;
    }

    > div {
      margin: 0;
    }
  `}

  ${({ hasError, theme }) => hasError && css`
    && label {
      border: 1px solid ${theme.colors.orange};
    }
  `}
`

const RadioButtonSt = styled.div`
  width: 100%;
  height: 3rem;
  position:relative;

  label {
    background-color: white;
    display: flex;
    align-items: center;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;

    padding: 0 1rem;
    // border: 1px solid ${({ theme }) =>  theme.colors.text02};
    box-shadow: ${({ theme }) => theme.boxShadows.shadow2};
    color:  ${({ theme }) =>  theme.colors.text02};
    font-family: Poppins, 'Montserrat';
    border-radius: 4px;
    cursor:pointer;
    z-index:90;
  }

  lavel:hover {
    background:#DDD;
  }

  input {
    display:block;
    position:absolute;
    top:0;
    left:0;
    right:0;
    bottom:0;
  }

  input[type="radio"]:checked + label::after {
    content: '';
    display: inline-block;
    width: 0.5rem;
    height: 0.5rem;
    border-radius: 50%;
    background-color: ${({ theme }) => theme.colors.brandRed};
    position: absolute;
    top: 50%;
    right: 0.75rem;
    transform: translate(-50%, -50%);
  }

  label::before {
    content: '';
    display: inline-block;
    width: 1rem;
    height: 1rem;
    border-radius: 50%;
    border: 2px solid ${({ theme }) => theme.colors.ui01};
    background-color: ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 50%;
    right: 0;
    transform: translate(-50%, -50%);
  }

  input[type="radio"]:checked + label::before {
    border: 2px solid ${({ theme }) => theme.colors.brandRed};
  }

  input[type="radio"] {
    opacity: 0.011;
    z-index:100;
  }

  input[type="radio"]:checked + label {
    border: 1px solid ${({ theme }) => theme.colors.brandRed};
    color: ${({ theme }) => theme.colors.brandRed};
    font-weight: 500;
  }
`

interface Props {
  name: string
  options: { id: string | number; value: string | number; text: string | React.ReactNode }[]
  register: any
  hasError: boolean
  inline?: boolean
}

const RadioButtonGroup = ({ options, name, register, hasError, inline }: Props) => {
  return (
    <RadioGroupSt inline={inline} hasError={hasError}>
      {options.map(option => (
        <RadioButtonSt key={option.id}>
          <input type="radio" id={`name-${option.id}`} name={name} ref={register({ required: true })} value={option.value} />
          <label htmlFor={`name-${option.id}`}>{option.text}</label>
        </RadioButtonSt>
      ))}
    </RadioGroupSt>
  )
}

export default RadioButtonGroup