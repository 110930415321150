interface Props {
  color?: string
}

const BurgerMenuIcon = ({ color = '#2D2D2D' }: Props) => {
  return (
    <svg width="24" height="15" fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 15">
      <path d="M0 13.8855h23.5379M0 7.4424h23.5379M0 1h23.5379" stroke={color} strokeWidth=".9928" />
    </svg>
  )
}

export default BurgerMenuIcon