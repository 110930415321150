import Select from "react-select";
import styled from "styled-components";
import { useCurrencyContext } from "../../../../contexts/CurrencyContext";
import { useResponsive } from "../../../../utils/hooks/useResponsive";
import { theme } from "../../../../utils/theme";
import { UISpacer } from "../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import { UITypography } from "../../../shared/atoms/UIComponents/UITypography/UITypography";

const FooterSt = styled.footer`
  border-top: 1px solid #dddddd;
  background-color: #f7f7f7;
  padding-bottom: 100px;
  ${({ theme }) => `${theme.breakpoints.md} {
    padding-bottom: 0;
  }`}
`;

const FooterContentSt = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 40px 20px;

  ${({ theme }) => `${theme.breakpoints.md} {
    flex-wrap: none;
  }`}

  ${({ theme }) => `${theme.breakpoints.lg} {
    padding: 40px 80px;
    max-width: 2000px;
    margin: 0 auto;
  }`}

  ${({ theme }) => `${theme.breakpoints.fiveXl} {
    max-width: 2560px;
  }`}
`;

const LinkContentSt = styled.div`
  font-family: Poppins, "Montserrat";
  display: block;
  color: ${({ theme }) => theme.colors.text02};

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const InstagramIconSt = styled.img`
  height: 30px;

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const FlexSt = styled.div`
  display: flex;

  div:first-child {
    margin-right: 40px;
  }
`;

const ExternalLinkSt = styled.a`
  font-family: Poppins, "Montserrat";
  text-decoration: none;
  color: ${({ theme }) => theme.colors.text02};

  &:hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const RightSt = styled.div`
  width: 200px;
  padding-top: 2rem;

  input {
    font-size: 16px;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    flex-wrap: none;
    padding-top: 0rem;
  }`}
`;

export const GuestPageFooter = () => {
  const { isTablet } = useResponsive();
  const { exchangeRates, selectedCurrency, setCurrency } = useCurrencyContext();

  const options = exchangeRates?.map((er) => ({
    value: er.currencyCode,
    label: er.currencyCode,
  }));

  const languageOptions = [
    { value: "en", label: "English" },
    { value: "es", label: "Spanish" },
  ];

  return (
    <FooterSt>
      <FooterContentSt>
        <div>
          <UITypography
            variant="body1"
            fontWeight={600}
            color={theme.colors.text01}
          >{`THAT'S THE ONE ${new Date().getFullYear()}`}</UITypography>
          <UISpacer />
          <FlexSt>
            {/* <div>
              <Link href="/about_us" passHref>
                <LinkContentSt>About</LinkContentSt>
              </Link>
              <UISpacer />
              <Link href="/contact_us" passHref>
                <LinkContentSt>Contact Us</LinkContentSt>
              </Link>
              <UISpacer />
            </div> */}
            <div>
              <ExternalLinkSt
                href="https://res.cloudinary.com/tto/image/upload/v1610218522/TTO_Privacy_Policy_uougse.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Privacy Policy
              </ExternalLinkSt>
              <UISpacer />
              <ExternalLinkSt
                href="https://res.cloudinary.com/tto/image/upload/v1610218524/TTO_T_Cs_nr8u4j.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Terms & Conditions
              </ExternalLinkSt>
              <UISpacer />
              <ExternalLinkSt
                href="https://res.cloudinary.com/tto/image/upload/v1610219970/TTO_cookie-policy_bxqyzl.pdf"
                target="_blank"
                rel="noopener noreferrer"
              >
                Cookie Policy
              </ExternalLinkSt>
            </div>
          </FlexSt>
        </div>

        <RightSt>
          <Select
            styles={{
              option: (provided: any) => ({
                ...provided,
                fontSize: 16,
              }),
              menuList: (provided: any, state: any) => ({
                ...provided,
                maxHeight: isTablet ? "300px" : "unset",
                paddingBottom: isTablet ? "4px" : "unset",
                paddingTop: isTablet ? "4px" : "unset",
                position: isTablet ? "relative" : "fixed",
                backgroundColor: theme.colors.white,
                ...(isTablet
                  ? {}
                  : {
                      top: 0,
                      left: 0,
                      bottom: 0,
                      right: 0,
                      height: "100%",
                    }),
              }),
            }}
            options={options}
            value={{ value: selectedCurrency, label: selectedCurrency }}
            onChange={(value: any) => {
              setCurrency(value.value);
            }}
            isSearchable={false}
          />
          <UISpacer />
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://www.instagram.com/thatstheone.io/"
          >
            <InstagramIconSt src="/images/instagram-black.svg" />
          </a>
        </RightSt>
      </FooterContentSt>
    </FooterSt>
  );
};
