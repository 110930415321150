import { RefObject } from "react";
import styled from "styled-components";
import { theme } from "../../../../../utils/theme";
import { Button } from "../../../../shared/atoms/Button/Button";
import { PerformantImage } from "../../../../shared/atoms/PerformantImage/PerformantImage";
import { UISpacer } from "../../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import { UITypography } from "../../../../shared/atoms/UIComponents/UITypography/UITypography";

const CardSt = styled.div`
  ${({ theme }) => `${theme.breakpoints.md} {
    display: flex;
  }`}
`;

const ImageContainerSt = styled.div`
  position: relative;
`;

const LockSt = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(255, 255, 255, 0.4);
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
  padding: 1rem;

  ${({ theme }) => `${theme.breakpoints.md} {
    align-items: center;
    justify-content: center;
  }`}
`;

const MobileOtherBookingsTagSt = styled.div`
  padding: 0.5rem 1rem;
  max-width: 180px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 4px;
  font-size: 0.625rem;
  color: ${({ theme }) => theme.colors.text01};
  position: absolute;
  top: 1rem;
  left: 8px;

  span {
    margin-right: 0.5rem;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    display: none;
  }`}
`;

const MainImageSt = styled(PerformantImage)`
  width: 100%;
  height: 100%;
  border-radius: 4px;
  object-fit: cover;

  ${({ theme }) => `${theme.breakpoints.md} {
    width: 240px;
  }`}
`;

const ContentSt = styled.div`
  padding: 1rem 0;
  flex-grow: 1;

  ${({ theme }) => `${theme.breakpoints.md} {
    padding: 1.5rem 0 0 1.5rem;
  }`}
`;

const MobileExplanationTextSt = styled.div`
  ${({ theme }) => `${theme.breakpoints.md} {
    display: none;
  }`}
`;

const ActionContainerSt = styled.div`
  display: flex;
  flex-direction: column;

  > p {
    display: none;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    align-items: flex-end;
    margin: 1.5rem 0 0;

    > p {
      display: block;
    }

    button {
      max-width: 200px;
    }
  }`}
`;

const OtherBookingsCardSt = styled.div`
  width: 220px;
  display: none;
  box-shadow: ${({ theme }) => theme.boxShadows.shadow1};
  border-radius: 4px;
  padding: 0.125rem 0.75rem;
  align-items: center;

  ${({ theme }) => `${theme.breakpoints.md} {
    display: flex;
  }`}
`;

const StrikeSt = styled.span`
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.brandRed};
  text-decoration: line-through;
`;

const EmojiIconSt = styled.div`
  font-size: 2rem;
  margin-right: 1rem;
`;

const GuestNamesSt = styled.p`
  font-size: 0.725rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.text02};
  margin: 0;
  font-family: Poppins, "Montserrat";
`;

const MoreNamesSt = styled.p`
  font-size: 0.725rem;
  line-height: 1rem;
  color: ${({ theme }) => theme.colors.text02};
  margin: 0;
  font-family: Poppins, "Montserrat";
`;

interface Props {
  rsvpRef: RefObject<HTMLDivElement>;
  hasRSVPd: boolean;
  hotelsComingSoonImageUrl: string;
}

const GuestPageHotelComingSoonCard = ({
  rsvpRef,
  hasRSVPd,
  hotelsComingSoonImageUrl,
}: Props) => {
  return (
    <CardSt>
      <ImageContainerSt>
        <MainImageSt
          cloudinaryURL={
            hotelsComingSoonImageUrl ||
            "https://res.cloudinary.com/tto/image/upload/v1624610704/staticImages/Tossa_de_Mar_kqow9g.jpg"
          }
          alt="Photo of the hotel"
        />
        <LockSt>
          <img src="https://res.cloudinary.com/tto/image/upload/v1624552621/secured-lock_1_j9opfi.svg" />
        </LockSt>
        <MobileOtherBookingsTagSt>
          <span>💝</span>{" "}
          {`Book here to secure unique wedding discounts & perks!`}
        </MobileOtherBookingsTagSt>
      </ImageContainerSt>
      <ContentSt>
        <UITypography variant="body3" color={theme.colors.text03}>
          Bookable Hotels, Villas, Airbnbs Coming Soon
        </UITypography>
        <UITypography variant="body1" fontSize="1.25rem">
          Exclusive rates are currently being negotiated for you!{" "}
        </UITypography>
        <UISpacer variant="sm" />
        <OtherBookingsCardSt>
          <EmojiIconSt>💝</EmojiIconSt>
          <div>
            <GuestNamesSt>{`Book here to secure unique wedding discounts & perks!`}</GuestNamesSt>
          </div>
        </OtherBookingsCardSt>
        <ActionContainerSt>
          {!hasRSVPd ? (
            <Button
              variant="5"
              onClick={() => {
                window.scrollTo({
                  left: 0,
                  top: (rsvpRef?.current?.offsetTop || 0) - 150,
                  behavior: "smooth",
                });
              }}
            >
              RSVP to be notified
            </Button>
          ) : (
            <MobileExplanationTextSt>
              <UITypography variant="body3Bold" color={theme.colors.brandRed}>
                You will receive an email once options are added.{" "}
              </UITypography>
            </MobileExplanationTextSt>
          )}
          <UISpacer variant="xs" />
          <UITypography variant="body3Bold" color={theme.colors.brandRed}>
            You will receive an email once options are added.{" "}
          </UITypography>
        </ActionContainerSt>
      </ContentSt>
    </CardSt>
  );
};

export default GuestPageHotelComingSoonCard;
