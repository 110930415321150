import { GetWeddingContract } from "@ttoengineering/delphi";
import { useEffect, useState } from "react";
import styled from "styled-components";
import { useResponsive } from "../../../../utils/hooks/useResponsive";
import { PerformantImage } from "../../../shared/atoms/PerformantImage/PerformantImage";
import { UISpacer } from "../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import { UITypography } from "../../../shared/atoms/UIComponents/UITypography/UITypography";
import DesktopPhotoGallery from "../../../shared/molecules/DesktopPhotoGallery/DesktopPhotoGallery";
import MobilePhotoGallery from "../../../shared/molecules/MobilePhotoGallery/MobilePhotoGallery";
import GuestPageSectionHeader from "../GuestPageSectionHeader/GuestPageSectionHeader";

const CommunityContainerSt = styled.div``;

const CommunityImageWithCreditSt = styled.div`
  position: relative;
  height: 220px;
  max-width: 390px;
  width: calc(50% - 5px);

  :nth-child(odd) {
    margin: 0 5px 0 0;
  }

  :nth-child(even) {
    margin: 0 0 0 5px;
  }

  p {
    position: absolute;
    bottom: 0.5rem;
    left: 0.75rem;
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.8;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    height: 490px;
    width: calc(50% - 15px);

    :nth-child(even) {
      margin: 10px 5px 10px 10px;
    }
  
    :nth-child(odd) {
      margin: 10px 10px 10px 5px;
    }
  }`}
`;

const CommunityImagesSt = styled.div`
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  max-width: 810px;

  :hover {
    cursor: pointer;
  }

  ${CommunityImageWithCreditSt}:nth-of-type(3) {
    display: none;
  }

  ${CommunityImageWithCreditSt}:nth-of-type(4) {
    display: none;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    
    ${CommunityImageWithCreditSt}:nth-of-type(3) {
      display: block;
    }

    ${CommunityImageWithCreditSt}:nth-of-type(4) {
      display: block;
    }
  }`}
`;

const CommunityImageSt = styled(PerformantImage)`
  display: block;
  object-fit: cover;
  height: 100%;
  width: 100%;
  border-radius: 2px;
`;

const TextContainerSt = styled.div`
  text-align: left;
`;

interface Props {
  wedding: GetWeddingContract;
}

const GuestPageTheVenueSection = ({ wedding }: Props) => {
  const [showPhotoGallery, setShowPhotoGallery] = useState(false);
  const [imageIdToDisplayOnOpen, setImageIdToDisplayOnOpen] = useState(
    wedding?.weddingHeroImages[0]?.id
  );
  const [showMobile, setShowMobile] = useState(false);
  const { isTablet } = useResponsive();

  useEffect(() => {
    setShowMobile(!isTablet);
  }, [isTablet]);

  const handleOpenPhotoGallery = (idToDisplay: number) => {
    setImageIdToDisplayOnOpen(idToDisplay);
    setShowPhotoGallery(true);
  };

  const fallback =
    "https://res.cloudinary.com/tto/image/upload/v1601117117/Frame_34_1_svl6uq.png";

  const theVenueDescriptionLines = wedding?.theVenueDescription?.split("|");

  return (
    <section>
      <GuestPageSectionHeader
        superTitle={wedding?.theVenueSupertitle}
        title={wedding?.theVenueTitle}
        themeAccentColor={wedding?.customTheme?.textColor}
      />
      <UISpacer />
      <TextContainerSt>
        {theVenueDescriptionLines?.map((line, index) => {
          return (
            <div key={index}>
              <UITypography variant="body2" key={index}>
                {line}
              </UITypography>
              <UISpacer variant="sm" />
            </div>
          );
        })}
      </TextContainerSt>
      <UISpacer variant="sm" />
      <CommunityContainerSt>
        <CommunityImagesSt>
          <CommunityImageWithCreditSt>
            <CommunityImageSt
              cloudinaryURL={wedding?.weddingVenueImages[0]?.url || fallback}
              onClick={() => {
                handleOpenPhotoGallery(wedding?.weddingVenueImages[0]?.id);
              }}
            />
            <UITypography variant="body3">
              {wedding?.weddingVenueImages[0]?.caption}
            </UITypography>
          </CommunityImageWithCreditSt>
          <CommunityImageWithCreditSt>
            <CommunityImageSt
              cloudinaryURL={wedding?.weddingVenueImages[1]?.url || fallback}
              onClick={() => {
                handleOpenPhotoGallery(wedding?.weddingVenueImages[1]?.id);
              }}
            />
            <UITypography variant="body3">
              {wedding?.weddingVenueImages[1]?.caption}
            </UITypography>
          </CommunityImageWithCreditSt>
          <CommunityImageWithCreditSt>
            <CommunityImageSt
              cloudinaryURL={wedding?.weddingVenueImages[2]?.url || fallback}
              onClick={() => {
                handleOpenPhotoGallery(wedding?.weddingVenueImages[2]?.id);
              }}
            />
            <UITypography variant="body3">
              {wedding?.weddingVenueImages[2]?.caption}
            </UITypography>
          </CommunityImageWithCreditSt>
          <CommunityImageWithCreditSt>
            <CommunityImageSt
              cloudinaryURL={wedding?.weddingVenueImages[3]?.url || fallback}
              onClick={() => {
                handleOpenPhotoGallery(wedding?.weddingVenueImages[3]?.id);
              }}
            />
            <UITypography variant="body3">
              {wedding?.weddingVenueImages[3]?.caption}
            </UITypography>
          </CommunityImageWithCreditSt>
        </CommunityImagesSt>
      </CommunityContainerSt>
      {!showMobile ? (
        <DesktopPhotoGallery
          images={wedding?.weddingVenueImages || []}
          imageIdToDisplayOnOpen={imageIdToDisplayOnOpen}
          isOpen={showPhotoGallery}
          onClose={() => {
            setImageIdToDisplayOnOpen(wedding?.weddingVenueImages[0]?.id);
            setShowPhotoGallery(false);
          }}
        />
      ) : (
        <MobilePhotoGallery
          isOpen={showPhotoGallery}
          onClose={() => setShowPhotoGallery(false)}
          images={wedding?.weddingVenueImages || []}
        />
      )}
    </section>
  );
};

export default GuestPageTheVenueSection;
