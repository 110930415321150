import { GetWeddingContract } from "@ttoengineering/delphi";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { Button } from "../../../shared/atoms/Button/Button";
import { UISpacer } from "../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import { UITypography } from "../../../shared/atoms/UIComponents/UITypography/UITypography";
import GuestPageSectionHeader from "../GuestPageSectionHeader/GuestPageSectionHeader";

interface Props {
  wedding: GetWeddingContract;
}

const ButtonContainerSt = styled.div`
  max-width: 300px;
  margin: 0 auto;

  button {
    display: block;
  }
`;

const TextContainerSt = styled.div`
  text-align: left;
`;

const GuestPageRegistrySection = ({ wedding }: Props) => {
  const giftRegistryDescriptionLines =
    wedding?.giftRegistryDescription?.split("|");

  return (
    <section>
      <GuestPageSectionHeader
        superTitle={wedding?.giftRegistrySupertitle}
        title={wedding?.giftRegistryTitle}
        themeAccentColor={wedding?.customTheme?.textColor}
      />
      <UISpacer />
      <TextContainerSt>
        {giftRegistryDescriptionLines?.map((line, index) => {
          return (
            <div key={index}>
              <UITypography variant="body2">{line}</UITypography>
              <UISpacer variant="sm" />
            </div>
          );
        })}
      </TextContainerSt>
      <UISpacer />
      <ButtonContainerSt>
        <Button
          styleOverrides={{
            backgroundColor:
              wedding?.customTheme?.filledButtonBackgroundColor ||
              theme.colors.brandRed,
            color: wedding?.customTheme?.filledButtonTextColor || "white",
          }}
          variant="4"
          onClick={() => window.open(wedding?.giftRegistryURL)}
        >
          {wedding.giftRegistryButton
            ? wedding.giftRegistryButton
            : "Go To Registry"}
        </Button>
      </ButtonContainerSt>
      <UISpacer />
    </section>
  );
};

export default GuestPageRegistrySection;
