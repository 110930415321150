import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { useForm } from "react-hook-form";
import { useRouter } from "next/router";
import shajs from "sha.js";
import {
  AmplitudeEventType,
  useAmplitudeContext,
} from "../../../../../contexts/AmplitudeContext";
import moment from "moment";
import { PerformantImage } from "../../../../shared/atoms/PerformantImage/PerformantImage";
import {
  GetWeddingContract,
  RSVPQuestionFieldTypeEnum,
  WeddingRSVPQuestionContract,
} from "@ttoengineering/delphi";
import Input from "../../../../shared/atoms/Input/Input";
import { UITypography } from "../../../../shared/atoms/UIComponents/UITypography/UITypography";
import { UISpacer } from "../../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import { Button } from "../../../../shared/atoms/Button/Button";
import TextArea from "../../../../shared/atoms/TextArea/TextArea";
import RadioButtonGroup from "../../../../shared/atoms/RadioButtonGroup/RadioButtonGroup";

const SuccessCardSt = styled.div`
  margin: 0 -1.5rem;
  background: rgba(50, 215, 129, 1);
  background: linear-gradient(
    90deg,
    rgba(50, 215, 129, 1) 0%,
    rgba(56, 195, 244, 1) 100%
  );
  padding: 2rem 1.5rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    max-width: 24rem;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    margin: 0;
    border-radius: 4px;
    text-align: left;
  }`}

  ${({ theme }) => `${theme.breakpoints.lg} {
    padding: 2rem 4rem;
  }`}
`;

interface PromoCardStProps {
  bkgColor: string | undefined;
}

const PromoCardSt = styled.div<PromoCardStProps>`
  margin: 0 -1.5rem;
  background: ${({ bkgColor }) => (bkgColor ? bkgColor : "rgba(255,120,3,1)")};
  background: ${({ bkgColor }) =>
    bkgColor
      ? bkgColor
      : "linear-gradient(90deg, rgba(235,22,89,1) 50%, rgba(255,120,3,1) 100%)"};
  padding: 2rem 1.5rem;
  text-align: center;

  ${({ theme }) => `${theme.breakpoints.md} {
    margin: 0;
    border-radius: 4px;
    text-align: left;
  }`}

  ${({ theme }) => `${theme.breakpoints.lg} {
    padding: 2rem 4rem;
  }`}
`;

const HeaderSt = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > div {
    span {
      margin: 0 0.5rem;
    }
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    justify-content: unset;
  }`}
`;

const HeaderCoupleImageSt = styled(PerformantImage)`
  display: none;

  ${({ theme }) => `${theme.breakpoints.md} {
    display: block;
    height: 8rem;
    width: 8rem;
    object-fit: cover;
    border-radius: 50%;
    margin-right: 2rem;
  }`}
`;

const ContentSt = styled.div`
  // padding: 2rem;
  // border-radius: 4px;
  // background-color: ${({ theme }) => theme.colors.white};
  // box-shadow: ${({ theme }) => theme.boxShadows.shadow2};
`;

const InlineFieldsSt = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  > * {
    margin: 0.625rem 0;
  }

  > :first-child {
    margin-top: 0;
  }

  > :last-child {
    margin-bottom: 0;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    flex-direction: row;

      margin: 0 -1rem;

      > * {
        margin: 0 1rem;
      }
  }`}
`;

interface ButtonStProps {
  disabled?: boolean;
  bkgColor: string | undefined;
  textColor: string | undefined;
}

const ButtonSt = styled.button<ButtonStProps>`
  display: flex;
  align-items: center;
  width: 11.5rem;
  background-color: ${({ theme, bkgColor }) =>
    bkgColor ? bkgColor : theme.colors.brandRed};
  border: 1px solid
    ${({ theme, bkgColor }) => (bkgColor ? bkgColor : theme.colors.white)};
  color: ${({ theme, textColor }) =>
    textColor ? textColor : theme.colors.white};
  height: 3rem;
  flex-shrink: 0;
  padding: 1rem;
  border-radius: 4px;
  margin-left: auto;

  img:nth-of-type(1) {
    display: inline;
  }

  img:nth-of-type(2) {
    display: none;
  }

  :hover {
    cursor: pointer;
  }

  ${({ disabled, theme }) =>
    disabled &&
    css`
      background-color: ${theme.colors.white};
      color: ${({ theme }) => theme.colors.text02};

      img:nth-of-type(1) {
        display: none;
      }

      img:nth-of-type(2) {
        display: inline;
      }

      :hover {
        cursor: wait;
      }
    `}
`;
const ButtonTextSt = styled.span`
  font-size: 1.0625rem;
  margin: 0 2rem 0 2rem;
  font-family: Poppins, "Montserrat";
`;

const ResestRSVPButtonSt = styled.button`
  font-family: Poppins, "Montserrat";
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};

  &:hover {
    cursor: pointer;
  }

  > span {
    text-decoration: underline;
  }
`;

interface FieldFactoryProps {
  rsvpQuestion: WeddingRSVPQuestionContract;
  register: any;
  errors: any;
}

const FieldFactory = ({
  rsvpQuestion,
  register,
  errors,
}: FieldFactoryProps) => {
  switch (rsvpQuestion.fieldType) {
    case RSVPQuestionFieldTypeEnum.SHORT_TEXT:
      return (
        <Input
          name={`answer`}
          placeholder="Type your answer here"
          register={() => register({ required: true })}
          hasError={Object.keys(errors).includes(`answer`)}
        />
      );

    case RSVPQuestionFieldTypeEnum.LONG_TEXT:
      return (
        <TextArea
          name={`answer`}
          placeholder="Type your answer here"
          register={() => register({ required: true })}
          hasError={Object.keys(errors).includes(`answer`)}
          rows={4}
        />
      );

    case RSVPQuestionFieldTypeEnum.RADIO:
      return (
        <RadioButtonGroup
          name="answer"
          options={rsvpQuestion.options.map((option) => ({
            id: option.id,
            value: option.text,
            text: option.text,
          }))}
          register={register}
          hasError={Object.keys(errors).includes("answer")}
        />
      );

    default:
      return <></>;
  }
};

interface Props {
  wedding: GetWeddingContract;
  handleRSVP: (b: boolean) => void;
  hasRSVPd: boolean;
}

export const GuestPageRSVPModule = ({
  wedding,
  handleRSVP,
  hasRSVPd,
}: Props) => {
  const { register, handleSubmit, watch, errors } = useForm();
  const watchIsAttendingValue = watch("isAttending");
  const router = useRouter();
  const [basicInfo, setBasicInfo] = useState({});
  const [rsvpCustomQuestionAnswers, setRsvpCustomQuestionAnswers] =
    useState<any>([]);
  const [stage, setStage] = useState(0);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { amplitudeLogEvent, amplitudeSetUserId } = useAmplitudeContext();

  useEffect(() => {
    if (hasRSVPd) {
      setSuccess(true);
    }
  }, [hasRSVPd]);

  const handleReset = () => {
    window.localStorage.removeItem(`${wedding.slug}-isAttending`);
    setBasicInfo({});
    setRsvpCustomQuestionAnswers([]);
    setStage(0);
    setSuccess(false);
    handleRSVP(false);
  };

  const onComplete = async (basicDetails: any, customRsvpResponses: any) => {
    setIsLoading(true);

    const rsvpResponse = await fetch(
      `${process.env.NEXT_PUBLIC_STRAPI_URL}/weddings/${encodeURIComponent(
        router.query.slug as string
      )}/rsvp`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...basicDetails,
          rsvpQuestionAndAnswer: customRsvpResponses,
        }),
      }
    );

    const jsonRsvpResponse = await rsvpResponse.json();

    if (jsonRsvpResponse.error) {
      setIsLoading(false);
      amplitudeLogEvent(AmplitudeEventType.GP_COMPLETED_RSVP_MODULE_ERROR, {
        wedding: wedding?.slug,
        error: jsonRsvpResponse.error,
      });
      alert(
        "There was a problem submitting your RSVP, please try again or contact support by emailing nina@thatstheone.io"
      );
    } else {
      window.localStorage.setItem("userEmail", basicDetails.email);
      window.localStorage.setItem("firstName", basicDetails.firstName);
      window.localStorage.setItem("lastName", basicDetails.lastName);
      window.localStorage.setItem(
        `${wedding.slug}-isAttending`,
        basicDetails.isAttending
      );
      setIsLoading(false);
      handleRSVP(true);
      amplitudeLogEvent(AmplitudeEventType.GP_COMPLETED_RSVP_MODULE_SUCCESS, {
        wedding: wedding?.slug,
      });
    }
  };

  const onNameFormSubmit = (data: any) => {
    amplitudeLogEvent(AmplitudeEventType.GP_SUBMITTED_NAME_RSVP_MODULE, {
      wedding: wedding?.slug,
    });
    setBasicInfo(data);

    const hashedEmail = shajs("sha256")
      .update(data?.email?.toLowerCase())
      .digest("hex");
    amplitudeSetUserId(hashedEmail);

    if (wedding.rsvpQuestions.length === 0 || data.isAttending === "false") {
      onComplete(data, []);
    } else {
      setStage(1);
    }
  };

  const onCustomRSVPQuestionSubmit = async (data: any) => {
    amplitudeLogEvent(
      AmplitudeEventType.GP_SUBMITTED_CUSTOM_QUESTION_RSVP_MODULE,
      { wedding: wedding?.slug }
    );

    if (stage < wedding.rsvpQuestions.length) {
      setRsvpCustomQuestionAnswers((prevState: any) => [
        ...prevState,
        {
          question: wedding.rsvpQuestions[stage - 1].question,
          answer: data.answer,
        },
      ]);
      setStage((s) => s + 1);
    } else {
      onComplete(basicInfo, [
        ...rsvpCustomQuestionAnswers,
        {
          question: wedding.rsvpQuestions[stage - 1].question,
          answer: data.answer,
        },
      ]);
    }
  };

  return (
    <>
      {!success && (
        <PromoCardSt bkgColor={wedding?.customTheme?.rsvpModuleBackgroundColor}>
          <HeaderSt>
            <div>
              <UITypography
                variant="body1"
                fontSize="2.125rem"
                lineHeight="3rem"
                color={wedding?.customTheme?.rsvpModuleTextColor || "white"}
                fontWeight={600}
                letterSpacing="1px"
              >
                RSVP to your invite
              </UITypography>
              <UITypography
                variant="body1"
                color={wedding?.customTheme?.rsvpModuleTextColor || "white"}
              >
                {wedding?.titleDate
                  ? wedding?.titleDate
                  : moment(wedding?.ceremonyDate)?.format("Do MMMM YYYY")}

                <span>|</span>
                {wedding?.location}
              </UITypography>
            </div>
          </HeaderSt>
          <UISpacer />

          <ContentSt>
            {stage === 0 && (
              <form onSubmit={handleSubmit(onNameFormSubmit)}>
                <UITypography
                  variant="body2"
                  color={wedding?.customTheme?.rsvpModuleTextColor || "white"}
                >
                  Enter your name and email to RSVP
                </UITypography>
                <UISpacer variant="sm" />
                <InlineFieldsSt>
                  <Input
                    name="firstName"
                    placeholder="First Name"
                    register={() => register({ required: true })}
                    hasError={Object.keys(errors).includes("firstName")}
                  />
                  <Input
                    name="lastName"
                    placeholder="Last Name"
                    register={() => register({ required: true })}
                    hasError={Object.keys(errors).includes("lastName")}
                  />
                </InlineFieldsSt>
                <UISpacer variant="sm" />
                <Input
                  name="email"
                  type="email"
                  placeholder="Email"
                  register={() => register({ required: true })}
                  hasError={Object.keys(errors).includes("email")}
                />
                <UISpacer variant="sm" />
                <UITypography
                  variant="body2"
                  color={wedding?.customTheme?.rsvpModuleTextColor || "white"}
                >
                  Will you be attending?
                </UITypography>
                <UISpacer variant="sm" />
                <RadioButtonGroup
                  name="isAttending"
                  options={[
                    { id: 1, value: "true", text: "Yes" },
                    { id: 2, value: "false", text: "No" },
                  ]}
                  register={register}
                  hasError={Object.keys(errors).includes("isAttending")}
                  inline
                />

                {watchIsAttendingValue === "false" ? (
                  <>
                    <UISpacer variant="sm" />
                    <UITypography
                      variant="body2"
                      color={
                        wedding?.customTheme?.rsvpModuleTextColor || "white"
                      }
                    >
                      Send your regrets (optional)
                    </UITypography>
                    <UISpacer variant="sm" />
                    <TextArea
                      name="generalComment"
                      placeholder="Share here..."
                      register={() => register()}
                      hasError={false}
                      rows={3}
                    />
                    <UISpacer />
                  </>
                ) : (
                  <UISpacer />
                )}

                <ButtonSt
                  type="submit"
                  disabled={isLoading}
                  bkgColor={
                    wedding?.customTheme?.rsvpModuleButtonBackgroundColor
                  }
                  textColor={wedding?.customTheme?.rsvpModuleButtonTextColor}
                >
                  <ButtonTextSt>Next</ButtonTextSt>
                  <img
                    src="https://res.cloudinary.com/tto/image/upload/v1620587507/staticIcons/arrow-white-right_zup5y5.svg"
                    alt="submit button"
                  />
                  <img
                    src="https://res.cloudinary.com/tto/image/upload/v1620634861/staticIcons/arrow-orange-right_ksmfhw.svg"
                    alt="submit button"
                  />
                </ButtonSt>
              </form>
            )}

            {wedding.rsvpQuestions.map((rsvpQuestion, index) => (
              <div key={rsvpQuestion.id}>
                {stage === index + 1 && (
                  <form onSubmit={handleSubmit(onCustomRSVPQuestionSubmit)}>
                    <UITypography
                      variant="body2"
                      color={
                        wedding?.customTheme?.rsvpModuleTextColor || "white"
                      }
                    >
                      {rsvpQuestion.question}
                    </UITypography>
                    <UISpacer variant="sm" />
                    <FieldFactory
                      rsvpQuestion={rsvpQuestion}
                      register={register}
                      errors={errors}
                    />
                    <UISpacer />
                    <ButtonSt
                      type="submit"
                      disabled={isLoading}
                      bkgColor={
                        wedding?.customTheme?.rsvpModuleButtonBackgroundColor
                      }
                      textColor={
                        wedding?.customTheme?.rsvpModuleButtonTextColor
                      }
                    >
                      <ButtonTextSt>Next</ButtonTextSt>
                      <img
                        src="https://res.cloudinary.com/tto/image/upload/v1620587507/staticIcons/arrow-white-right_zup5y5.svg"
                        alt="submit button"
                      />
                      <img
                        src="https://res.cloudinary.com/tto/image/upload/v1620634861/staticIcons/arrow-orange-right_ksmfhw.svg"
                        alt="submit button"
                      />
                    </ButtonSt>
                  </form>
                )}
              </div>
            ))}
          </ContentSt>
        </PromoCardSt>
      )}

      {success && (
        <SuccessCardSt>
          <UISpacer variant="sm" />
          <UITypography
            variant="h2"
            fontSize="1.5rem"
            component="p"
            color="white"
            fontWeight={600}
            letterSpacing="1px"
          >{`Thank you for RSVP'ing! 🎉`}</UITypography>
          <UITypography
            variant="body1"
            color="white"
          >{`We've sent you an email to confirm!`}</UITypography>
          <UISpacer />
          <ResestRSVPButtonSt onClick={handleReset}>
            <span>RSVP for someone else</span>
          </ResestRSVPButtonSt>
        </SuccessCardSt>
      )}
    </>
  );
};

export default GuestPageRSVPModule;
