import moment from "moment";
import { RefObject } from "react";
import styled from "styled-components";
import {
  GetWeddingContract,
  WeddingHotelWithAvailabilityAndRatesContract,
} from "@ttoengineering/delphi";
import GuestPageSectionHeader from "../GuestPageSectionHeader/GuestPageSectionHeader";
import { UISpacer } from "../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import { UITypography } from "../../../shared/atoms/UIComponents/UITypography/UITypography";
import { theme } from "../../../../utils/theme";
import GuestPageHotelCard from "../GuestPageHotelCard/GuestPageHotelCard";
import GuestPageNonintegratedHotelCard from "../GuestPageNonIntegratedHotelCard/GuestPageNonIntegratedHotelCard";
import GuestPageRSVPModule from "./GuestPageRSVPModule/GuestPageRSVPModule";
import GuestPageHotelComingSoonCard from "./GuestPageHotelComingSoonCard/GuestPageHotelComingSoonCard";
import { Button } from "../../../shared/atoms/Button/Button";

const TextContainerSt = styled.div`
  text-align: left;
`;

const HrSt = styled.hr`
  border: 1px solid ${({ theme }) => theme.colors.ui02};
  width: 100%;
  opacity: 0.8;
`;

const ButtonContainerSt = styled.div`
  max-width: 300px;
  margin: 0 auto;

  button {
    display: block;
  }
`;

const getRandomInt = (max: number) => {
  return Math.floor(Math.random() * Math.floor(max));
};

export enum TimeLimitCopyExperimentOptions {
  A = "A",
  B = "B",
}

interface Props {
  hotels: WeddingHotelWithAvailabilityAndRatesContract[];
  wedding: GetWeddingContract;
  rsvpRef: RefObject<HTMLDivElement>;
  handleRSVP: (b: boolean) => void;
  hasRSVPd: boolean;
  hideRSVPModule: boolean;
  hideHotelModule: boolean;
}

const GuestPageBookTripSection = ({
  hotels,
  wedding,
  rsvpRef,
  handleRSVP,
  hasRSVPd,
  hideRSVPModule,
  hideHotelModule,
}: Props) => {
  return (
    <section>
      <GuestPageSectionHeader
        superTitle={wedding?.todoListSupertitle}
        title={wedding?.todoListTitle}
        themeAccentColor={wedding?.customTheme?.textColor}
      />
      <UISpacer variant="sm" />
      <TextContainerSt>
        <UITypography variant="body2">
          {wedding?.todoListDescription}
        </UITypography>
        <UISpacer />
        {!hideRSVPModule && (
          <>
            <UITypography
              variant="body1"
              color={theme.colors.text03}
            >{`Step 1: RSVP by ${moment(wedding?.rsvpDeadline)?.format(
              "Do MMM YYYY"
            )}`}</UITypography>
            <UISpacer variant="sm" />
            {wedding?.rsvpCustomURL ? (
              <>
                <UISpacer variant="sm" />
                <ButtonContainerSt>
                  <Button
                    styleOverrides={{
                      backgroundColor:
                        wedding?.customTheme?.filledButtonBackgroundColor ||
                        theme.colors.brandRed,
                      color:
                        wedding?.customTheme?.filledButtonTextColor || "white",
                    }}
                    variant="4"
                    onClick={() =>
                      window.open(wedding?.rsvpCustomURL as string)
                    }
                  >
                    {wedding.rsvpCustomURLButton
                      ? wedding.rsvpCustomURLButton
                      : "RSVP Here"}
                  </Button>
                </ButtonContainerSt>
              </>
            ) : (
              <GuestPageRSVPModule
                wedding={wedding}
                handleRSVP={handleRSVP}
                hasRSVPd={hasRSVPd}
              />
            )}

            <UISpacer variant="sm" />
          </>
        )}
        {!hideHotelModule && !hideRSVPModule && (
          <>
            <HrSt />
            <UISpacer variant="sm" />
          </>
        )}
        {!hideHotelModule && (
          <>
            <UITypography variant="body1" color={theme.colors.text03}>
              {hideRSVPModule
                ? `Step 1: Select one of our handpicked hotels`
                : `Step 2: Select one of our handpicked hotels`}
            </UITypography>
            <UISpacer variant="sm" />
            {wedding?.accommodationCustomURL ? (
              <>
                <UISpacer variant="sm" />
                <ButtonContainerSt>
                  <Button
                    styleOverrides={{
                      backgroundColor:
                        wedding?.customTheme?.filledButtonBackgroundColor ||
                        theme.colors.brandRed,
                      color:
                        wedding?.customTheme?.filledButtonTextColor || "white",
                    }}
                    variant="4"
                    onClick={() =>
                      window.open(wedding?.accommodationCustomURL as string)
                    }
                  >
                    {wedding?.accommodationCustomURLButton
                      ? wedding?.accommodationCustomURLButton
                      : "Book Here"}
                  </Button>
                </ButtonContainerSt>
              </>
            ) : (
              <>
                {hotels?.map((hotel) => (
                  <div key={hotel.id}>
                    <GuestPageHotelCard
                      hotelListing={hotel}
                      customTheme={wedding?.customTheme}
                      weddingStartDate={wedding?.startDate}
                      weddingEndDate={wedding?.endDate}
                    />
                    <UISpacer variant="sm" />
                    <HrSt />
                    <UISpacer variant="sm" />
                  </div>
                ))}

                {wedding.nonIntegratedHotelsForGuests.map((hotel) => (
                  <div key={hotel.id}>
                    <GuestPageNonintegratedHotelCard
                      hotelListing={hotel}
                      customTheme={wedding?.customTheme}
                    />
                    <UISpacer variant="sm" />
                    <HrSt />
                    <UISpacer variant="sm" />
                  </div>
                ))}
                {[
                  ...hotels,
                  ...wedding.nonIntegratedHotelsForGuests,
                ].length === 0 && (
                  <>
                    <GuestPageHotelComingSoonCard
                      rsvpRef={rsvpRef}
                      hasRSVPd={hasRSVPd}
                      hotelsComingSoonImageUrl={wedding?.hotelsComingSoon?.url}
                    />
                    <UISpacer variant="sm" />
                    <HrSt />
                    <UISpacer variant="sm" />
                  </>
                )}
              </>
            )}
          </>
        )}
      </TextContainerSt>
    </section>
  );
};

export default GuestPageBookTripSection;
