import { useEffect, useState } from "react";
import moment from "moment";
import styled from "styled-components";
import { PerformantImage } from "../../../shared/atoms/PerformantImage/PerformantImage";
import { GetWeddingContract } from "@ttoengineering/delphi";
import { useResponsive } from "../../../../utils/hooks/useResponsive";
import TTOCarousel from "../../../shared/molecules/Carousel/Carousel";
import { UITypography } from "../../../shared/atoms/UIComponents/UITypography/UITypography";
import { UISpacer } from "../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import { Button } from "../../../shared/atoms/Button/Button";
import DesktopPhotoGallery from "../../../shared/molecules/DesktopPhotoGallery/DesktopPhotoGallery";
import MobilePhotoGallery from "../../../shared/molecules/MobilePhotoGallery/MobilePhotoGallery";
import ReactLinkify from "react-linkify";

const HeroSt = styled.div`
  display: flex;
  max-width: 1820px;
  flex-direction: column;

  ${({ theme }) => `${theme.breakpoints.md} {
    margin: 3.125rem auto 0;
    height: 60vh;
    flex-direction: row;
  }`}

  ${({ theme }) => `${theme.breakpoints.mlg} {
    margin: 0 auto;
    height: calc(100vh - 13.25rem);
  }`}

  ${({ theme }) => `${theme.breakpoints.xxl} {
    height: calc(100vh - 14.5rem);
  }`}

  ${({ theme }) => `${theme.breakpoints.fiveXl} {
    height: calc(80vh - 14.5rem);
    max-width: 2560px;
  }`}
`;
const HeroContentSt = styled.div`
  margin: 0 auto;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  padding: 20px 30px;
  border-top-right-radius: 12px;
  border-top-left-radius: 12px;
  margin-top: -10px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 9;

  h1 {
    margin: 1rem 0 0 0;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    width: 50%;
    max-width: 500px;
    padding: 0 60px 0 40px;
    margin-top: 0px;

    h1 {
      margin: 0 0 2rem 0;
    }
  }`}
`;

const MobileHeroImagesSt = styled.div`
  display: block;

   ${({ theme }) => `${theme.breakpoints.md} {
    display: none;
   }`}
  }
`;

const HeroImagesSt = styled.div`
  padding: 0 40px 0 0;
  width: 50%;
  height: 100%;

  :hover {
    cursor: pointer;
  }

  display: none;

   ${({ theme }) => `${theme.breakpoints.md} {
    display: block;
   }`}
  }
`;

const MainImageSt = styled(PerformantImage)`
  object-fit: cover;
  height: 60%;
  width: 100%;
  margin-bottom: 10px;
  border-radius: 2px;
`;

const SecondaryImagesContainerSt = styled.div`
  display: flex;
  height: calc(40% - 10px);
  position: relative;
`;

const SecondaryImageSt = styled(PerformantImage)`
  object-fit: cover;
  object-position: 40% 60%;
  height: 100%;
  width: calc(50% - 5px);
  border-radius: 2px;

  :nth-child(1) {
    margin-right: 10px;
  }
`;

const SeeAllPhotosBtnContainerSt = styled.div`
  position: absolute;
  bottom: 25px;
  right: 20px;
`;

const HrSt = styled.hr`
  border: 1px solid black;
  width: 100%;
  opacity: 0.8;
`;

interface Props {
  wedding: GetWeddingContract;
}

const GuestPageHeroSection = ({ wedding }: Props) => {
  const [showPhotoGallery, setShowPhotoGallery] = useState(false);
  const [imageIdToDisplayOnOpen, setImageIdToDisplayOnOpen] = useState(
    wedding?.weddingHeroImages[0]?.id
  );
  const [showMobile, setShowMobile] = useState(false);
  const { isTablet } = useResponsive();

  useEffect(() => {
    setShowMobile(!isTablet);
  }, [isTablet]);

  const handleOpenPhotoGallery = (idToDisplay: number) => {
    setImageIdToDisplayOnOpen(idToDisplay);
    setShowPhotoGallery(true);
  };

  const fallback =
    "https://res.cloudinary.com/tto/image/upload/v1601117117/Frame_34_1_svl6uq.png";

  const inviteLines = wedding?.invite?.split("|");

  return (
    <>
      <HeroSt>
        <MobileHeroImagesSt
          onClick={() => {
            handleOpenPhotoGallery(wedding?.weddingHeroImages[0]?.id);
          }}
        >
          <TTOCarousel
            imageUrls={wedding?.weddingHeroImages.map((wi) => wi.url)}
            height="70vh"
          />
        </MobileHeroImagesSt>
        <HeroContentSt>
          <UITypography variant="h3" component="p">
            {wedding?.superTitle}
          </UITypography>
          <UITypography variant="h1" fontSize="2.5rem" lineHeight="2.75rem">
            {wedding?.title}
          </UITypography>
          {wedding?.titleDate ? (
            <UITypography variant="h3" lineHeight="1.5rem">
              {wedding?.titleDate}
            </UITypography>
          ) : (
            <UITypography variant="h3" lineHeight="2.75rem">
              {moment(wedding?.ceremonyDate)?.format("dddd, Do MMMM YYYY")}
            </UITypography>
          )}
          <UITypography variant="h3" lineHeight="2.75rem">
            {wedding?.location}
          </UITypography>
          <UISpacer variant="sm" />
          <HrSt />
          <UISpacer variant="sm" />
          {inviteLines?.map((line, index) => {
            return (
              <ReactLinkify key={index}>
                <UITypography variant="body2">{line}</UITypography>
                {inviteLines.length !== index + 1 && <UISpacer variant="sm" />}
              </ReactLinkify>
            );
          })}
          {/* <UITypography variant="body1">{wedding?.invite}</UITypography> */}
        </HeroContentSt>
        <HeroImagesSt>
          <MainImageSt
            cloudinaryURL={wedding?.weddingHeroImages[0]?.url || fallback}
            onClick={() =>
              handleOpenPhotoGallery(wedding?.weddingHeroImages[0]?.id)
            }
          />
          <SecondaryImagesContainerSt>
            <SecondaryImageSt
              cloudinaryURL={wedding?.weddingHeroImages[1]?.url || fallback}
              onClick={() =>
                handleOpenPhotoGallery(wedding?.weddingHeroImages[1]?.id)
              }
            />
            <SecondaryImageSt
              cloudinaryURL={wedding?.weddingHeroImages[2]?.url || fallback}
              onClick={() =>
                handleOpenPhotoGallery(wedding?.weddingHeroImages[2]?.id)
              }
            />
            <SeeAllPhotosBtnContainerSt>
              <Button
                variant="2"
                onClick={() =>
                  handleOpenPhotoGallery(wedding?.weddingHeroImages[0]?.id)
                }
              >
                See All Photos
              </Button>
            </SeeAllPhotosBtnContainerSt>
          </SecondaryImagesContainerSt>
        </HeroImagesSt>
      </HeroSt>
      {!showMobile ? (
        <DesktopPhotoGallery
          images={wedding?.weddingHeroImages || []}
          imageIdToDisplayOnOpen={imageIdToDisplayOnOpen}
          isOpen={showPhotoGallery}
          onClose={() => {
            setImageIdToDisplayOnOpen(wedding?.weddingHeroImages[0]?.id);
            setShowPhotoGallery(false);
          }}
        />
      ) : (
        <MobilePhotoGallery
          isOpen={showPhotoGallery}
          onClose={() => setShowPhotoGallery(false)}
          images={wedding?.weddingHeroImages || []}
        />
      )}
    </>
  );
};

export default GuestPageHeroSection;
