import { RefObject, useState } from 'react'
import styled from 'styled-components'
import BurgerMenuIcon from '../../../shared/atoms/Icons/BurgerMenuIcon/BurgerMenuIcon'
import GuestPageMenu from './GuestPageMenu/GuestPageMenu'


const HeaderSt = styled.header`
  position: relative;
  max-width: 1820px;
  margin: 0 auto;
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;

  ${({ theme }) => `${theme.breakpoints.md} {
    padding: 1rem 40px;
    justify-content: space-between;
  }`}
`

const MarkSt = styled.h1`
  font-size: 1.25rem;
  font-weight: 700;
  font-family: Poppins, 'Montserrat';
  white-space: nowrap;
  letter-spacing: 8%;
  color: ${({ theme }) => theme.colors.text02};

  ${({ theme }) => `${theme.breakpoints.md} {
    font-size: 1.625rem;
  }`}
`

const SpacerDivSt = styled.div`
  display: none;
  width: 36px;

  ${({ theme }) => `${theme.breakpoints.md} {
    display: block;
  }`}
`

const ButtonSt = styled.button`
  margin: auto;
  position: absolute;
  top: 0; bottom: 0; right: 1.5rem;

  &:hover {
    cursor: pointer;
  }

  &:focus {
    outline: none;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    margin: unset;
    position: unset;
  }`}
`

interface Props {
  ourStorySectionRef: RefObject<HTMLDivElement>
  theVenueSectionRef: RefObject<HTMLDivElement>
  theBridalPartySectionRef: RefObject<HTMLDivElement>
  theItinerarySectionRef: RefObject<HTMLDivElement>
  rsvpSectionRef: RefObject<HTMLDivElement>
  registrySectionRef: RefObject<HTMLDivElement>
  faqsSectionRef: RefObject<HTMLDivElement>
  hideRSVPModule: boolean
  hideHotelModule: boolean
  hideFAQs: boolean
  bridalPartySectionText: string | null
}


export const GuestPageSiteHeader = (props : Props) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false)

  return (
    <HeaderSt>
      <SpacerDivSt />
      <MarkSt>{`THAT'S THE ONE`}</MarkSt>
      <GuestPageMenu 
        {...props}
        isOpen={isMenuOpen}
        onCloseModal={() => setIsMenuOpen(false)}
      />
      <ButtonSt onClick={() => setIsMenuOpen(true)}>
        <BurgerMenuIcon />
      </ButtonSt>
    </HeaderSt>
  )
}