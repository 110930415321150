import React, { useEffect, useState } from 'react'
import styled from 'styled-components'

interface InputStProps {
  hasError: boolean
  disabled?: boolean
}

const InputSt = styled.input<InputStProps>`
  width: 100%;
  display: block;
  height: 3rem;
  padding: 0 1rem;
  border-radius: 5px;
  border: 1px solid ${({ theme, hasError }) => hasError ? theme.colors.orange : 'transparent'};
  font-family: 'Poppins';
  font-size: 1.145rem;
  color: ${({ theme }) => theme.colors.text02};
  box-shadow: ${({ theme }) => theme.boxShadows.shadow2};

  background-color: ${({ disabled, theme }) => disabled && theme.colors.ui02};

  ::placeholder {
    color: ${({ theme, hasError }) => hasError ? theme.colors.orange : theme.colors.text03};
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    font-size: 1rem;
  }`}
`

const InputStCheckoutForm = styled.input<InputStProps>`
  width: 100%;
  display: block;
  height: 3.875rem;
  padding: 0 1rem;
  border-radius: 5px;
  border: 1px solid ${({ theme, hasError }) => hasError ? theme.colors.brandRed : theme.colors.text02};
  font-family: 'Poppins';
  font-size: 1.145rem;
  color: ${({ theme }) => theme.colors.text02};

  ::placeholder {
    color: ${({ theme, hasError }) => hasError ? theme.colors.brandRed : theme.colors.text03};
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    font-size: 1rem;
  }`}
`

interface Props {
  name: string
  type?: string
  placeholder?: string
  register: () => any
  hasError?: boolean
  isCheckoutFormInput?: boolean // N.B: THIS IS criminallllly shit code but it's 1am and who cares
}

const Input = (props: Props) => {
  return (<>{props.isCheckoutFormInput ? (
    <InputStCheckoutForm {...props} hasError={props.hasError || false} placeholder={props.placeholder} ref={props.register()} />
  ) : (
    <InputSt {...props} hasError={props.hasError || false} placeholder={props.placeholder} ref={props.register()} />
  )}</>)
}

export default Input