import { useEffect, useState } from "react";
import moment from "moment";
import Collapsible from "react-collapsible";
import styled, { css } from "styled-components";
import { UITypography } from "../../../shared/atoms/UIComponents/UITypography/UITypography";
import { theme } from "../../../../utils/theme";
import { UISpacer } from "../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import GuestPageSectionHeader from "../GuestPageSectionHeader/GuestPageSectionHeader";
import { GetWeddingContract, ItineraryEventContract, WeddingItineraryItemIconTypeEnum } from "@ttoengineering/delphi";

interface TimelineContainerStProps {
  themeAccentColor: string | undefined;
}

const TimelineContainerSt = styled.div<TimelineContainerStProps>`
  display: none;
  text-align: left;
  margin-left: 2rem;
  position: relative;

  ::before {
    content: "";
    display: inline-block;
    border: 1px solid
      ${({ theme, themeAccentColor }) =>
        themeAccentColor || theme.colors.brandRed};
    position: absolute;
    top: 6rem;
    bottom: 6rem;
    left: -2rem;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    display: block;
  }`}
`;

interface DayContainerStProps {
  themeAccentColor: string | undefined;
}

const DayContainerSt = styled.div<DayContainerStProps>`
  position: relative;

  ::before {
    content: "";
    display: inline-block;
    border: 1px solid ${({ theme }) => theme.colors.white};
    position: absolute;
    top: 0;
    height: 6rem;
    left: -2rem;
  }

  ::after {
    content: "";
    display: inline-block;
    border: 1px dashed
      ${({ theme, themeAccentColor }) =>
        themeAccentColor || theme.colors.brandRed};
    position: absolute;
    top: 0;
    height: 6rem;
    left: -2rem;
  }
`;

interface ItineraryCardStProps {
  themeAccentColor: string | undefined;
}

const ItineraryCardSt = styled.div<ItineraryCardStProps>`
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  width: 100%;
  padding: 1.5rem 2rem;
  display: flex;
  margin-bottom: 1.25rem;
  position: relative;

  p {
    word-break: break-word;
  }

  ::after {
    content: "";
    display: inline-block;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    border: 8px solid white;
    background-color: ${({ theme, themeAccentColor }) =>
      themeAccentColor || theme.colors.brandRed};
    position: absolute;
    top: 50%;
    left: -2rem;
    margin-left: 1px;
    transform: translate(-50%, -50%);
  }
`;

const IconSt = styled.img`
  width: 3.375rem;
`;

const LeftCardContentSt = styled.div`
  width: 35%;
  display: flex;
  align-items: center;
  border-right: 1px solid ${({ theme }) => theme.colors.ui01};

  > div {
    padding: 0 1.25rem 0 2.25rem;

    p:first-of-type {
      margin-bottom: 0.25rem;
    }

    // margin: 0 auto;
    // text-align: center;
  }
`;

const RightCardContentSt = styled.div`
  width: 65%;
  display: flex;
  padding: 1rem 1rem 1rem 3rem;
  flex-direction: column;
  justify-content: space-between;
`;

const MobileTimelineContainerSt = styled.div`
  text-align: left;

  ${({ theme }) => `${theme.breakpoints.md} {
    display: none;
  }`}
`;

const DaySelectorContainerSt = styled.div`
  display: flex;
  width: calc(100vw - 3rem);
  overflow: scroll;
  padding: 0.5rem 0.5rem 1.5rem;
`;

interface DayCardStProps {
  isActive: boolean;
  themeAccentColor: string | undefined;
}

const DayCardSt = styled.div<DayCardStProps>`
  flex-shrink: 0;
  text-align: center;
  padding: 1rem;
  border-radius: 4px;
  width: 95px;
  background-color: ${({ theme, isActive, themeAccentColor }) =>
    isActive ? themeAccentColor || theme.colors.brandRed : theme.colors.white};
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  margin-right: 0.725rem;

  ${({ theme, isActive }) =>
    isActive &&
    css`
      p {
        color: ${theme.colors.white};
      }
    `}
`;

interface MobileItineraryCardsContainerStProps {
  themeAccentColor: string | undefined;
}

const MobileItineraryCardsContainerSt = styled.div<MobileItineraryCardsContainerStProps>`
  margin-left: 6rem;
  position: relative;

  ::before {
    content: "";
    display: inline-block;
    border: 1px solid
      ${({ theme, themeAccentColor }) =>
        themeAccentColor || theme.colors.brandRed};
    position: absolute;
    top: 0;
    bottom: 0;
    left: -2rem;
  }
`;

interface MobileItineraryCardStProps {
  startTime: string;
  themeAccentColor: string | undefined;
}

const MobileItineraryCardSt = styled.div<MobileItineraryCardStProps>`
  box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 1rem;
  position: relative;

  ${({ startTime, theme }) => css`
    ::before {
      content: "${startTime.replace(/\s+/g, "")}";
      display: inline-block;
      position: absolute;
      top: 50%;
      left: -4.5rem;
      font-size: 0.75rem;
      font-family: Poppins, "Montserrat";
      transform: translate(-50%, -50%);
      color: ${theme.colors.text03};
    }
  `}

  ::after {
    content: "";
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    border: 5px solid white;
    background-color: ${({ theme, themeAccentColor }) =>
      themeAccentColor || theme.colors.brandRed};
    position: absolute;
    top: 50%;
    left: -2rem;
    margin-left: 1px;
    transform: translate(-50%, -50%);
  }
`;

const MobileTopCardContentSt = styled.div`
  display: flex;
  align-items: center;

  img {
    margin-right: 1rem;
  }

  p {
    word-break: break-word;
  }
`;

const MobileIconSt = styled.img`
  width: 3rem;
`;

interface CaretIconStProps {
  isOpen: boolean;
}

const CaretIconSt = styled.img<CaretIconStProps>`
  position: absolute;
  right: 10px;
  bottom: 6px;

  ${({ isOpen }) => isOpen && `transform: rotate(180deg)`};
`;

const MobileDrawerCardContentSt = styled.div`
  border-radius: 4px;
  padding: 1rem 2rem 0rem;
  background-color: #f7f7f7;
`;

const iconTypeToIconUrl = (iconType: WeddingItineraryItemIconTypeEnum) => {
  switch (iconType) {
    case WeddingItineraryItemIconTypeEnum.RING:
      return "https://res.cloudinary.com/tto/image/upload/v1624556978/Wedding_Day_pg6no4.svg";

    case WeddingItineraryItemIconTypeEnum.PLANE:
      return "https://res.cloudinary.com/tto/image/upload/v1624557107/Arrival_mjp73h.svg";

    case WeddingItineraryItemIconTypeEnum.CAR:
      return "https://res.cloudinary.com/tto/image/upload/v1624557106/Departure_j0robb.svg";

    case WeddingItineraryItemIconTypeEnum.WINE_GLASSES:
      return "https://res.cloudinary.com/tto/image/upload/v1624638896/staticIcons/wine-glasses_osm9en.svg";

    case WeddingItineraryItemIconTypeEnum.FOUNTAIN:
      return "https://res.cloudinary.com/tto/image/upload/v1624638896/staticIcons/fountain_fdnwbv.svg";

    case WeddingItineraryItemIconTypeEnum.CLOCHE:
      return "https://res.cloudinary.com/tto/image/upload/v1624638895/staticIcons/cloche_jiwpkk.svg";

    case WeddingItineraryItemIconTypeEnum.CHURCH:
      return "https://res.cloudinary.com/tto/image/upload/v1624638883/staticIcons/Church_zsu4nc.svg";

    case WeddingItineraryItemIconTypeEnum.STAR_CONFETTI:
      return "https://res.cloudinary.com/tto/image/upload/v1626886692/staticIcons/starConfetti_oj4se3.svg";

    case WeddingItineraryItemIconTypeEnum.BEACH_PARASOL:
      return "https://res.cloudinary.com/tto/image/upload/v1626886692/staticIcons/beachParasol_csvo2p.svg";

    default:
      return "https://res.cloudinary.com/tto/image/upload/v1624556978/Wedding_Day_pg6no4.svg";
  }
};

const ItineraryAccordionHeader = ({
  item,
  isOpen,
  themeAccentColor,
}: {
  item: ItineraryEventContract;
  isOpen: boolean;
  themeAccentColor: string | undefined;
}) => {
  return (
    <MobileItineraryCardSt
      key={item.id}
      startTime={item.time ? item.time : ""}
      themeAccentColor={themeAccentColor}
    >
      <MobileTopCardContentSt>
        {item.iconType !== WeddingItineraryItemIconTypeEnum.NO_ICON && (
          <MobileIconSt src={iconTypeToIconUrl(item.iconType)} alt="Icon" />
        )}
        <div>
          <UITypography variant="body1" fontWeight={600} lineHeight="1.5rem">
            {item.title}
          </UITypography>
          <UITypography variant="body3" color={theme.colors.text03}>
            {item.location}
          </UITypography>
        </div>
      </MobileTopCardContentSt>
      <UISpacer variant="sm" />
      <CaretIconSt
        src="https://res.cloudinary.com/tto/image/upload/v1623163279/staticIcons/caret-down.svg"
        alt="Arrow down"
        isOpen={isOpen}
      />
    </MobileItineraryCardSt>
  );
};

interface Props {
  wedding: GetWeddingContract;
}

const GuestPageItinerarySection = ({ wedding }: Props) => {
  const [activeMobileId, setActiveMobileId] = useState<number>();
  const [openMobileIteneraryItems, setOpenMobileIteneraryItems] = useState<any>(
    []
  );

  useEffect(() => {
    if (wedding?.itineraryDays) {
      setActiveMobileId(wedding?.itineraryDays[0]?.id);
    }
  }, [wedding]);

  useEffect(() => {
    if (wedding?.itineraryDays) {
      const defaultOpenEvents: number[] = [];

      wedding?.itineraryDays?.map((itinDay) => {
        itinDay.itineraryEvents?.map((event) => {
          if (event.mobileAccordionDefaultOpen) {
            defaultOpenEvents.push(event.id);
          }
        });
      });

      setOpenMobileIteneraryItems(defaultOpenEvents);
    }
  }, [wedding]);

  return (
    <section>
      <GuestPageSectionHeader
        superTitle={wedding?.theItinerarySupertitle}
        title={wedding?.theItineraryTitle}
        themeAccentColor={wedding?.customTheme?.textColor}
      />

      <MobileTimelineContainerSt>
        <UISpacer variant="sm" />
        <UITypography variant="body3" color={theme.colors.text03}>
          Click on a day below to view the respective itinerary.
        </UITypography>
        <UISpacer variant="sm" />
        <DaySelectorContainerSt>
          {wedding?.itineraryDays?.map((itineraryDay) => (
            <DayCardSt
              key={itineraryDay.id}
              isActive={activeMobileId === itineraryDay.id}
              onClick={() => setActiveMobileId(itineraryDay.id)}
              themeAccentColor={wedding?.customTheme?.accentColor}
            >
              <UITypography
                variant="body2"
                lineHeight="1.5rem"
                color={wedding?.customTheme?.textColor || theme.colors.brandRed}
              >
                {itineraryDay.dayText}
              </UITypography>
              <UITypography
                variant="body2"
                lineHeight="1.5rem"
                color={theme.colors.text03}
              >
                {moment(itineraryDay.date)?.format("ddd")}
              </UITypography>
              <UISpacer variant="sm" />
              <UITypography
                variant="h3"
                component="p"
                fontSize="1.85rem"
                lineHeight="2rem"
                color={wedding?.customTheme?.textColor || theme.colors.brandRed}
              >
                {moment(itineraryDay.date)?.format("D")}
              </UITypography>
              <UITypography variant="body1">
                {moment(itineraryDay.date)?.format("MMMM")}
              </UITypography>
            </DayCardSt>
          ))}
        </DaySelectorContainerSt>
        <UISpacer />
        <MobileItineraryCardsContainerSt
          themeAccentColor={wedding?.customTheme?.accentColor}
        >
          {wedding?.itineraryDays
            .find((itinDay) => itinDay.id === activeMobileId)
            ?.itineraryEvents?.map((item: ItineraryEventContract) => (
              <div key={item.id}>
                <Collapsible
                  onOpen={() => {
                    setOpenMobileIteneraryItems((currentState: any) => [
                      ...currentState,
                      item.id,
                    ]);
                  }}
                  onClose={() => {
                    setOpenMobileIteneraryItems((currentState: any) =>
                      [...currentState].filter((id) => id !== item.id)
                    );
                  }}
                  trigger={
                    <ItineraryAccordionHeader
                      item={item}
                      isOpen={openMobileIteneraryItems.includes(item.id)}
                      themeAccentColor={
                        wedding?.customTheme?.filledButtonBackgroundColor
                      }
                    />
                  }
                  transitionTime={200}
                  open={!!item.mobileAccordionDefaultOpen}
                >
                  <MobileDrawerCardContentSt>
                    {item.description?.split("|").map((para: string) => (
                      <span key={para}>
                        <UITypography variant="body2">
                          {para}
                        </UITypography>
                        <UISpacer variant="sm" />
                      </span>
                    ))}
                  </MobileDrawerCardContentSt>
                </Collapsible>
                <UISpacer variant="sm" />
              </div>
            ))}
        </MobileItineraryCardsContainerSt>
      </MobileTimelineContainerSt>

      <TimelineContainerSt themeAccentColor={wedding?.customTheme?.accentColor}>
        <UISpacer variant="lg" />
        {wedding?.itineraryDays?.map((itineraryDay) => (
          <DayContainerSt
            key={itineraryDay.id}
            themeAccentColor={wedding?.customTheme?.accentColor}
          >
            <UITypography variant="h4" lineHeight="0.875rem">
              {itineraryDay.dayText}
            </UITypography>
            <UITypography variant="body1">
              {moment(itineraryDay.date)?.format("dddd, Do MMMM")}
            </UITypography>
            <UISpacer variant="xs" />
            {itineraryDay.itineraryEvents?.map(
              (item: ItineraryEventContract) => (
                <ItineraryCardSt
                  key={item.id}
                  themeAccentColor={wedding?.customTheme?.accentColor}
                >
                  <LeftCardContentSt>
                    {item.iconType !==
                      WeddingItineraryItemIconTypeEnum.NO_ICON && (
                      <IconSt
                        src={iconTypeToIconUrl(item.iconType)}
                        alt="Icon"
                      />
                    )}
                    <div>
                      <UITypography
                        variant="body1"
                        fontWeight={500}
                        lineHeight="1.5rem"
                      >
                        {item.title}
                      </UITypography>
                      {item.time && (
                        <UITypography
                          variant="body1"
                          lineHeight="1.5rem"
                          color={theme.colors.text03}
                        >
                          {item.time}
                        </UITypography>
                      )}
                    </div>
                  </LeftCardContentSt>
                  <RightCardContentSt>
                    {item.description?.split("|").map((para: string) => (
                      <span key={para}>
                        <UITypography variant="body2">
                          {para}
                        </UITypography>
                        <UISpacer variant="sm" />
                      </span>
                    ))}

                    <UITypography variant="body3" color={theme.colors.text03}>
                      {item.location}
                    </UITypography>
                  </RightCardContentSt>
                </ItineraryCardSt>
              )
            )}
            <UISpacer />
          </DayContainerSt>
        ))}
      </TimelineContainerSt>
    </section>
  );
};

export default GuestPageItinerarySection;
