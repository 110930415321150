interface Props {
  color?: string
  orientation: 'left' | 'right'
}

const CaretIcon = ({ color='#DE3332', orientation  }: Props) => {
  const scale = orientation === 'right' ? 'scale(1,-1)' : 'scale(-1,1)'

  return (
    <svg width="11" height="21" viewBox="0 0 11 21" fill="none" xmlns="http://www.w3.org/2000/svg" transform={scale}>
      <path d="M1 20L10 10.5L1 1" stroke={color}/>
    </svg>
  )
}

export default CaretIcon