import styled from "styled-components";

interface TextAreaStProps {
  hasError: boolean;
  disabled?: boolean;
}

const TextAreaSt = styled.textarea<TextAreaStProps>`
  resize: vertical;
  width: 100%;
  display: block;
  padding: 1rem;
  min-height: 4rem;
  border-radius: 5px;
  border: 1px solid
    ${({ theme, hasError }) => (hasError ? theme.colors.orange : "transparent")};
  font-family: "Poppins";
  font-size: 1.145rem;
  color: ${({ theme }) => theme.colors.text02};
  background-color: ${({ disabled, theme }) => disabled && theme.colors.ui02};

  ::placeholder {
    color: ${({ theme, hasError }) =>
      hasError ? theme.colors.orange : theme.colors.text03};
  }

  :focus-visible {
    outline: none;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    font-size: 1rem;
  }`}
`;

interface Props {
  name: string;
  type?: string;
  placeholder?: string;
  register: () => any;
  hasError?: boolean;
  enableI18n?: boolean;
  rows: number;
}

const TextArea = (props: Props) => {
  return (
    <TextAreaSt
      {...props}
      hasError={props.hasError || false}
      placeholder={props.placeholder}
      ref={props.register()}
    />
  );
};

export default TextArea;
