import { RefObject, useEffect, useState, useMemo } from "react";
import styled from "styled-components";

interface LiProps {
  isActive: boolean;
}

const Li = styled.li<LiProps>`
  a {
    white-space: nowrap;
    font-family: "Poppins";
    font-weight: 500;
    letter-spacing: 0.125rem;
    color: ${({ theme }) => theme.colors.text01};
    text-decoration: none;
    line-height: 2.5rem;
    font-size: 0.75rem;

    ${({ theme }) => `${theme.breakpoints.xxl} {
      font-size: 0.875rem;
    }`}

    ${({ theme }) => `${theme.breakpoints.xxxl} {
      font-size: 0.875rem;
    }`}
  }

  ${({ isActive }) =>
    isActive &&
    `
    a {
      font-weight: 700 !important;
    }
  `}
`;

const NavSt = styled.nav`
  text-align: left;
  position: sticky;
  top: 200px;
  margin-right: 100px;

  ul {
    padding-inline-start: 0;
    width: 130px;
    list-style: none;
  }
`;

interface Props {
  ourStorySectionRef: RefObject<HTMLDivElement>;
  theVenueSectionRef: RefObject<HTMLDivElement>;
  theBridalPartySectionRef: RefObject<HTMLDivElement>;
  theItinerarySectionRef: RefObject<HTMLDivElement>;
  rsvpSectionRef: RefObject<HTMLDivElement>;
  registrySectionRef: RefObject<HTMLDivElement>;
  faqsSectionRef: RefObject<HTMLDivElement>;
  hideRSVPModule: boolean;
  hideHotelModule: boolean;
  hideFAQs: boolean;
  bridalPartySectionText: string | null
}

const GuestPageSidebar = ({
  ourStorySectionRef,
  theVenueSectionRef,
  theBridalPartySectionRef,
  theItinerarySectionRef,
  rsvpSectionRef,
  registrySectionRef,
  faqsSectionRef,
  hideRSVPModule,
  hideHotelModule,
  hideFAQs,
  bridalPartySectionText,
}: Props) => {
  const [scrollTop, setScrollTop] = useState(0);

  const ourStorySectionScrollTop = useMemo(
    () => (ourStorySectionRef?.current?.offsetTop || 0) - 400,
    [ourStorySectionRef?.current?.offsetTop]
  );

  const theVenueSectionScrollTop = useMemo(
    () => (theVenueSectionRef?.current?.offsetTop || 0) - 400,
    [theVenueSectionRef?.current?.offsetTop]
  );

  const theBridalPartySectionScrollTop = useMemo(
    () => (theBridalPartySectionRef?.current?.offsetTop || 0) - 400,
    [theBridalPartySectionRef?.current?.offsetTop]
  );

  const theItinerarySectionScrollTop = useMemo(
    () => (theItinerarySectionRef?.current?.offsetTop || 0) - 400,
    [theItinerarySectionRef?.current?.offsetTop]
  );

  const rsvpSectionScrollTop = useMemo(
    () => (rsvpSectionRef?.current?.offsetTop || 0) - 400,
    [rsvpSectionRef?.current?.offsetTop]
  );

  const registrySectionScrollTop = useMemo(
    () => (registrySectionRef?.current?.offsetTop || 0) - 400,
    [registrySectionRef?.current?.offsetTop]
  );

  const faqsSectionScrollTop = useMemo(
    () => (faqsSectionRef?.current?.offsetTop || 0) - 400,
    [faqsSectionRef?.current?.offsetTop]
  );

  const orderedSectionRefs = [
    ourStorySectionScrollTop,
    theVenueSectionScrollTop,
    theBridalPartySectionScrollTop,
    theItinerarySectionScrollTop,
    rsvpSectionScrollTop,
    registrySectionScrollTop,
    faqsSectionScrollTop,
  ];

  const nextRefThatIsDefinedWithAPositiveOffsetTop = (
    currentRefIndex: number
  ): number => {
    const orderedSectionRefsCopy = [...orderedSectionRefs];

    return (
      orderedSectionRefsCopy
        .slice(currentRefIndex + 1)
        .find((refScrollTop) => refScrollTop > 0) || faqsSectionScrollTop // Because always will be FAQ section
    );
  };

  const sectionsTopAndBottom = useMemo(
    () => ({
      ourStorySection: {
        top: ourStorySectionScrollTop,
        bottom: nextRefThatIsDefinedWithAPositiveOffsetTop(0),
      },
      theVenueSection: {
        top: theVenueSectionScrollTop,
        bottom: nextRefThatIsDefinedWithAPositiveOffsetTop(1),
      },
      theBridalPartySection: {
        top: theBridalPartySectionScrollTop,
        bottom: nextRefThatIsDefinedWithAPositiveOffsetTop(2),
      },
      theItinerarySection: {
        top: theItinerarySectionScrollTop,
        bottom: nextRefThatIsDefinedWithAPositiveOffsetTop(3),
      },
      rsvpSection: {
        top: rsvpSectionScrollTop,
        bottom: nextRefThatIsDefinedWithAPositiveOffsetTop(4),
      },
      registrySection: {
        top: registrySectionScrollTop,
        bottom: nextRefThatIsDefinedWithAPositiveOffsetTop(5),
      },
    }),
    [
      ourStorySectionScrollTop,
      theVenueSectionScrollTop,
      theBridalPartySectionScrollTop,
      theItinerarySectionScrollTop,
      rsvpSectionScrollTop,
      registrySectionScrollTop,
      faqsSectionScrollTop,
    ]
  );

  const scrollToRef = (ref: RefObject<HTMLDivElement>) =>
    window?.scrollTo({
      left: 0,
      top: (ref?.current?.offsetTop || 0) - 150,
      behavior: "smooth",
    });

  useEffect(() => {
    const onScroll = (e: any) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <NavSt>
      <ul>
        {ourStorySectionRef?.current && (
          <Li
            isActive={
              scrollTop >= sectionsTopAndBottom.ourStorySection.top &&
              scrollTop < sectionsTopAndBottom.ourStorySection.bottom
            }
          >
            <a
              href="#ourStory"
              onClick={(e) => {
                e.preventDefault();
                scrollToRef(ourStorySectionRef);
              }}
            >
              OUR STORY
            </a>
          </Li>
        )}
        {theVenueSectionRef?.current && (
          <Li
            isActive={
              scrollTop >= sectionsTopAndBottom.theVenueSection.top &&
              scrollTop < sectionsTopAndBottom.theVenueSection.bottom
            }
          >
            <a
              href="#theVenue"
              onClick={(e) => {
                e.preventDefault();
                scrollToRef(theVenueSectionRef);
              }}
            >
              THE VENUE
            </a>
          </Li>
        )}
        {theBridalPartySectionRef?.current && (
          <Li
            isActive={
              scrollTop >= sectionsTopAndBottom.theBridalPartySection.top &&
              scrollTop < sectionsTopAndBottom.theBridalPartySection.bottom
            }
          >
            <a
              href="#theBridalParty"
              onClick={(e) => {
                e.preventDefault();
                scrollToRef(theBridalPartySectionRef);
              }}
            >
              {bridalPartySectionText?.toUpperCase() || 'THE BRIDAL PARTY'}
            </a>
          </Li>
        )}
        {theItinerarySectionRef?.current && (
          <Li
            isActive={
              scrollTop >= sectionsTopAndBottom.theItinerarySection.top &&
              scrollTop < sectionsTopAndBottom.theItinerarySection.bottom
            }
          >
            <a
              href="#theItinerary"
              onClick={(e) => {
                e.preventDefault();
                scrollToRef(theItinerarySectionRef);
              }}
            >
              THE ITINERARY
            </a>
          </Li>
        )}
        {rsvpSectionRef?.current && (
          <Li
            isActive={
              scrollTop >= sectionsTopAndBottom.rsvpSection.top &&
              scrollTop < sectionsTopAndBottom.rsvpSection.bottom
            }
          >
            <a
              href="#rsvp"
              onClick={(e) => {
                e.preventDefault();
                scrollToRef(rsvpSectionRef);
              }}
            >
              {hideRSVPModule && !hideHotelModule && "BOOK TRIP"}
              {!hideRSVPModule && hideHotelModule && "RSVP"}
              {!hideRSVPModule && !hideHotelModule && "RSVP & BOOK TRIP"}
            </a>
          </Li>
        )}
        {registrySectionRef?.current && (
          <Li
            isActive={
              scrollTop >= sectionsTopAndBottom.registrySection.top &&
              scrollTop < sectionsTopAndBottom.registrySection.bottom
            }
          >
            <a
              href="#registry"
              onClick={(e) => {
                e.preventDefault();
                scrollToRef(registrySectionRef);
              }}
            >
              GIFT REGISTRY
            </a>
          </Li>
        )}
        {faqsSectionRef?.current && !hideFAQs && (
          <Li isActive={scrollTop >= faqsSectionScrollTop}>
            <a
              href="#faqs"
              onClick={(e) => {
                e.preventDefault();
                scrollToRef(faqsSectionRef);
              }}
            >
              FAQS
            </a>
          </Li>
        )}
      </ul>
    </NavSt>
  );
};

export default GuestPageSidebar;
