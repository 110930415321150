import Head from "next/head";
import * as Sentry from "@sentry/node";
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useRouter } from "next/router";
import { NextPageContext } from "next";
import {
  GetWeddingContract,
  WeddingHotelWithAvailabilityAndRatesContract,
  WeddingStatusEnum,
} from "@ttoengineering/delphi";
import { cloudinaryImageURLToWidth } from "../../../utils/cloudinary";
import { UISpacer } from "../../../components/shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import { GuestPageSiteHeader } from "../../../components/modules/guestPage/GuestPageSiteHeader/GuestPageSiteHeader";
import GuestPageHeroSection from "../../../components/modules/guestPage/GuestPageHeroSection/GuestPageHeroSection";
import GuestPageCTABar from "../../../components/modules/guestPage/GuestPageCTABar/GuestPageCTABar";
import GuestPageSidebar from "../../../components/modules/guestPage/GuestPageSidebar/GuestPageSidebar";
import GuestPageOurStorySection from "../../../components/modules/guestPage/GuestPageOurStorySection/GuestPageOurStorySection";
import GuestPageTheVenueSection from "../../../components/modules/guestPage/GuestPageTheVenueSection/GuestPageTheVenueSection";
import GuestPageGettingHereSection from "../../../components/modules/guestPage/GuestPageGettingHereSection/GuestPageGettingHereSection";
import GuestPageBridalPartySection from "../../../components/modules/guestPage/GuestPageBridalPartySection/GuestPageBridalPartySection";
import GuestPageItinerarySection from "../../../components/modules/guestPage/GuestPageItinerarySection/GuestPageItinerarySection";
import GuestPageBookTripSection from "../../../components/modules/guestPage/GuestPageBookTripSection/GuestPageBookTripSection";
import GuestPageRegistrySection from "../../../components/modules/guestPage/GuestPageRegistrySection/GuestPageRegistrySection";
import GuestPageFAQSection from "../../../components/modules/guestPage/GuestPageFAQSection/GuestPageFAQSection";
import { GuestPageFooter } from "../../../components/modules/guestPage/GuestPageFooter/GuestPageFooter";
import {
  AmplitudeEventType,
  useAmplitudeContext,
} from "../../../contexts/AmplitudeContext";

const PreviewBannerSt = styled.div`
  width: 1440px;
  text-align: center;
  background-color: #df333c;
  width: 100%;
  padding: 1rem;
`;

const PreviewTitleSt = styled.p`
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0.05em;
  text-align: center;
  color: white;
  margin: 0;
`;

const PreviewSubTitleSt = styled.p`
  font-family: Poppins;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
  letter-spacing: 0.02em;
  text-align: center;
  color: white;
`;

const ContainerWithSidebar = styled.div`
  display: flex;
  text-align: center;
  margin: 0 auto;
  padding: 0 1.5rem;

  > div:first-of-type {
    display: none;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    padding: 0 3rem;
  }`}

  ${({ theme }) => `${theme.breakpoints.mlg} {
    max-width: 960px;
    
    > div:first-of-type {
      display: block;
    }
  }`}

  ${({ theme }) => `${theme.breakpoints.lg} {
    max-width: 1080px;
  }`}

  ${({ theme }) => `${theme.breakpoints.xlg} {
    max-width: 1080px;
  }`}

  ${({ theme }) => `${theme.breakpoints.xxl} {
    max-width: 1400px;
  }`}

  ${({ theme }) => `${theme.breakpoints.fiveXl} {
    max-width: 1920px;
  }`}
`;

const ContentSt = styled.div`
  margin: 0 auto;
  max-width: 885px;

  ${({ theme }) => `${theme.breakpoints.fiveXl} {
    max-width: 1200px;
  }`}
`;

interface Props {
  wedding: GetWeddingContract;
  error: any;
}

const GuestPage = ({ wedding }: Props) => {
  const [hotels, setHotels] = useState<
    WeddingHotelWithAvailabilityAndRatesContract[]
  >([]);
  const [hasRSVPd, setHasRSVPd] = useState(false);
  const [isFirstLoad, setIsFirstLoaded] = useState(true);

  const ourStorySectionRef = useRef(null);
  const theVenueSectionRef = useRef(null);
  const theBridalPartySectionRef = useRef(null);
  const theItinerarySectionRef = useRef(null);
  const rsvpSectionRef = useRef(null);
  const faqsSectionRef = useRef(null);
  const registrySectionRef = useRef(null);
  const router = useRouter();

  const { amplitudeLogEvent, amplitudeClient } = useAmplitudeContext();

  useEffect(() => {
    if (isFirstLoad && wedding && amplitudeLogEvent && amplitudeClient) {
      amplitudeLogEvent(AmplitudeEventType.GP_LOADED_PAGE, {
        template: "Full Page",
        slug: wedding?.slug,
      });
      setIsFirstLoaded(false);
    }
  }, [isFirstLoad, wedding, amplitudeLogEvent, amplitudeClient]);

  useEffect(() => {
    if (window.localStorage.getItem(`${wedding?.slug}-isAttending`)) {
      setHasRSVPd(true);
    }
  }, [wedding]);

  useEffect(() => {
    if (!wedding) {
      router.push("/404");
    }
  }, [router, wedding]);

  useEffect(() => {
    if (!wedding)
      return console.error("no wedding when trying to fetch hotels");

    const fetchHotels = async () => {
      const hotelsResponse = await fetch(
        `${process.env.NEXT_PUBLIC_STRAPI_URL}/weddings/${encodeURIComponent(
          wedding.slug
        )}/hotels?startDate=${wedding.startDate}&endDate=${wedding.endDate}`
      );
      const json = await hotelsResponse.json();
      setHotels(json);
    };

    fetchHotels();
  }, [wedding]);

  return (
    <>
      <Head>
        <title>{wedding?.title}</title>
        <meta name="description" content={wedding?.invite}></meta>
        <meta name="robots" content="noindex"></meta>
        <meta
          property="og:title"
          content={`${wedding?.title} invite you to their wedding`}
        />
        <meta property="og:description" content={`${wedding?.invite}`} />
        <meta
          property="og:image"
          content={
            cloudinaryImageURLToWidth(
              wedding?.weddingHeroImages[0]?.url,
              1200,
              1200
            ).desktop
          }
        />
      </Head>
      {(wedding?.status === WeddingStatusEnum.CREATED ||
        wedding?.status === WeddingStatusEnum.SUBMITTED_FOR_REVIEW) && (
        <PreviewBannerSt>
          <PreviewTitleSt>PREVIEW</PreviewTitleSt>
          <PreviewSubTitleSt>{`Submit your page and we’ll let you know once it’s ready to share with guests`}</PreviewSubTitleSt>
        </PreviewBannerSt>
      )}
      <GuestPageSiteHeader
        ourStorySectionRef={ourStorySectionRef}
        theVenueSectionRef={theVenueSectionRef}
        theBridalPartySectionRef={theBridalPartySectionRef}
        theItinerarySectionRef={theItinerarySectionRef}
        rsvpSectionRef={rsvpSectionRef}
        faqsSectionRef={faqsSectionRef}
        registrySectionRef={registrySectionRef}
        hideRSVPModule={!!wedding?.hideRSVPModule}
        hideHotelModule={!!wedding?.hideHotelModule}
        hideFAQs={wedding?.faqs?.length === 0}
        bridalPartySectionText={wedding?.bridalPartySupertitle}
      />
      <GuestPageHeroSection wedding={wedding} />
      {(!wedding?.hideRSVPModule || !wedding?.hideHotelModule) && (
        <GuestPageCTABar
          wedding={wedding}
          rsvpSectionRef={rsvpSectionRef}
          hideRSVPModule={!!wedding?.hideRSVPModule}
          hideHotelModule={!!wedding?.hideHotelModule}
        />
      )}
      <UISpacer variant="lg" />
      {wedding && (
        <ContainerWithSidebar>
          <div>
            <GuestPageSidebar
              ourStorySectionRef={ourStorySectionRef}
              theVenueSectionRef={theVenueSectionRef}
              theBridalPartySectionRef={theBridalPartySectionRef}
              theItinerarySectionRef={theItinerarySectionRef}
              rsvpSectionRef={rsvpSectionRef}
              faqsSectionRef={faqsSectionRef}
              registrySectionRef={registrySectionRef}
              hideRSVPModule={!!wedding?.hideRSVPModule}
              hideHotelModule={!!wedding?.hideHotelModule}
              hideFAQs={wedding?.faqs?.length === 0}
              bridalPartySectionText={wedding?.bridalPartySupertitle}
            />
          </div>
          <ContentSt>
            {wedding?.ourStoryTitle && wedding?.ourStoryTitle.length > 1 && (
              <>
                <div ref={ourStorySectionRef} />
                <GuestPageOurStorySection
                  wedding={wedding}
                  onPhotoGalleryOpen={() => console.log("FIXME")}
                />
                <UISpacer variant="lg" />
              </>
            )}
            {(wedding?.theVenueTitle || wedding?.theVenueSupertitle) && (
              <>
                <div ref={theVenueSectionRef} />
                <GuestPageTheVenueSection wedding={wedding} />
                <UISpacer variant="lg" />
              </>
            )}

            <GuestPageGettingHereSection wedding={wedding} />
            {wedding?.bridalPartyTitle && (
              <>
                <div ref={theBridalPartySectionRef} />
                <UISpacer variant="lg" />
                <GuestPageBridalPartySection
                  wedding={wedding}
                  onPhotoGalleryOpen={() => console.log("FIXME")}
                />
              </>
            )}

            <UISpacer variant="lg" />
            <div ref={theItinerarySectionRef} />
            <GuestPageItinerarySection wedding={wedding} />
            {(!wedding?.hideRSVPModule || !wedding?.hideHotelModule) && (
              <>
                <UISpacer variant="lg" />
                <div ref={rsvpSectionRef} />
                <GuestPageBookTripSection
                  hotels={hotels}
                  wedding={wedding}
                  rsvpRef={rsvpSectionRef}
                  handleRSVP={setHasRSVPd}
                  hasRSVPd={hasRSVPd}
                  hideRSVPModule={!!wedding?.hideRSVPModule}
                  hideHotelModule={!!wedding?.hideHotelModule}
                />
              </>
            )}
            {wedding?.giftRegistryURL && (
              <>
                <div ref={registrySectionRef} />
                <UISpacer variant="lg" />
                <GuestPageRegistrySection wedding={wedding} />
              </>
            )}
            <UISpacer variant="lg" />
            <div ref={faqsSectionRef} />
            {wedding?.faqs?.length > 0 && (
              <>
                <GuestPageFAQSection wedding={wedding} />
                <UISpacer variant="lg" />
              </>
            )}
          </ContentSt>
        </ContainerWithSidebar>
      )}
      <GuestPageFooter />
    </>
  );
};

export async function getServerSideProps(context: NextPageContext) {
  let wedding;
  let error;

  try {
    const weddingResponse = await fetch(
      `${process.env.NEXT_PUBLIC_STRAPI_URL}/weddings/${encodeURIComponent(
        context.query.slug as string
      )}`
    );

    const jsonWeddingRes = await weddingResponse.json();

    wedding = jsonWeddingRes;
  } catch (e) {
    Sentry.captureException(e);
    error = e as any;
  }

  return {
    props: {
      wedding: wedding || null,
      error: error?.message || null,
    },
  };
}

export default GuestPage;
