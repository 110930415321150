import { GetWeddingContract } from "@ttoengineering/delphi";
import { useState } from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import CaretIcon from "../../../shared/atoms/Icons/CaretIcon/CaretIcon";
import { PerformantImage } from "../../../shared/atoms/PerformantImage/PerformantImage";
import { UISpacer } from "../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import { UITypography } from "../../../shared/atoms/UIComponents/UITypography/UITypography";
import MobilePhotoGallery from "../../../shared/molecules/MobilePhotoGallery/MobilePhotoGallery";
import GuestPageSectionHeader from "../GuestPageSectionHeader/GuestPageSectionHeader";

const HeaderWithControlsSt = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin: 0 auto 1rem;

  ${({ theme }) => `${theme.breakpoints.fiveXl} {
    max-width: unset;
  }`}
`;

interface ButtonControlStProps {
  themeAccentColor: string | undefined;
}

const ButtonControlSt = styled.button<ButtonControlStProps>`
  height: 35px;
  width: 35px;
  background-color: ${({ disabled, theme, themeAccentColor }) =>
    disabled ? "white" : themeAccentColor || theme.colors.brandRed};
  border: ${({ disabled, theme, themeAccentColor }) =>
    disabled
      ? `1px solid ${themeAccentColor || theme.colors.brandRed}`
      : "none"};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 10px;

  &:hover {
    cursor: ${({ disabled }) => (disabled ? "not-allowed" : "pointer")};
    ${({ disabled }) =>
      !disabled &&
      "box-shadow: 0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)"};
  }
`;

const ControlsSt = styled.div`
  display: flex;

  ${ButtonControlSt}:nth-of-type(1) {
    padding-right: 8px;
  }

  ${ButtonControlSt}:nth-of-type(2) {
    padding-left: 11px;
  }
`;

const FactsCarouselSt = styled.div`
  display: none;

  ${({ theme }) => `${theme.breakpoints.md} {
    display: flex;
    margin: 0 auto;
    flex-wrap: wrap;

    p {
      text-align: left;
    }
  }`}
`;

const FactCardsContainerSt = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const FactCardSt = styled.div`
  width: 32%;
  max-width: 380px;

  ${({ theme }) => `${theme.breakpoints.fiveXl} {
    max-width: unset;
  }`}
`;

const FactImageWithCreditSt = styled.div`
  position: relative;
  height: 270px;
  margin-bottom: 20px;

  p {
    position: absolute;
    bottom: 0.5rem;
    left: 0.75rem;
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.8;
  }
`;

const FactImageSt = styled(PerformantImage)`
  display: block;
  object-fit: cover;
  width: 100%;
  height: 100%;
  border-radius: 2px;
`;

const TextContainerSt = styled.div`
  text-align: left;
`;

const ImageShowcaseSt = styled.div`
  ${({ theme }) => `${theme.breakpoints.md} {
    display: none;
  }`}
`;

const MainImageWithCreditSt = styled.div`
  position: relative;
  height: 180px;
  width: 100%;
  margin-bottom: 10px;

  p {
    position: absolute;
    bottom: 0;
    left: 0.625rem;
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.9;
    width: calc(100% - 0.625rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const MainImageSt = styled(PerformantImage)`
  height: 100%;
  width: 100%;
  object-fit: cover;
  display: block;
`;

const SecondaryImagesSt = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const SecondaryImageWithCreditSt = styled.div`
  position: relative;
  height: 160px;
  width: calc(50% - 5px);

  p {
    position: absolute;
    bottom: 0;
    left: 0.625rem;
    color: ${({ theme }) => theme.colors.white};
    opacity: 0.9;
    width: calc(100% - 0.625rem);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const SecondaryImageSt = styled(PerformantImage)`
  height: 100%;
  width: 100%;
  display: block;
  object-fit: cover;
`;

const ButtonSt = styled.button`
  width: 100%;
  padding: 10px 0;
  text-align: center;
  border: 1px solid ${({ theme }) => theme.colors.darkGrey};
  border-radius: 4px;
`;

interface Props {
  wedding: GetWeddingContract;
  onPhotoGalleryOpen: () => void;
}

const GuestPageOurStorySection = ({ wedding, onPhotoGalleryOpen }: Props) => {
  const [skip, setSkip] = useState(0);
  const [showPhotoGallery, setShowPhotoGallery] = useState(false);
  const backButtonDisabled = skip === 0;
  const nextButtonDisabled = wedding?.stories.length - 3 <= skip;
  const ourStoryDescriptionLines = wedding?.ourStoryDescription?.split("|");

  return (
    <section>
      <GuestPageSectionHeader
        superTitle={wedding?.ourStorySupertitle}
        title={wedding?.ourStoryTitle}
        themeAccentColor={wedding?.customTheme?.textColor}
      />
      <UISpacer />
      <TextContainerSt>
        {ourStoryDescriptionLines?.map((line, index) => {
          return (
            <div key={index}>
              <UITypography variant="body2">{line}</UITypography>
              <UISpacer variant="sm" />
            </div>
          );
        })}
      </TextContainerSt>
      <UISpacer variant="sm" />
      <FactsCarouselSt>
        <HeaderWithControlsSt>
          <UITypography variant="body3" color={theme.colors.pebble}>
            {""}
          </UITypography>
          {wedding?.stories.length > 3 && (
            <ControlsSt>
              <ButtonControlSt
                themeAccentColor={
                  wedding?.customTheme?.filledButtonBackgroundColor
                }
                onClick={() => {
                  setSkip((s) => s - 1);
                }}
                disabled={backButtonDisabled}
              >
                <CaretIcon
                  color={
                    backButtonDisabled
                      ? wedding?.customTheme?.accentColor ||
                        theme.colors.brandRed
                      : wedding?.customTheme?.filledButtonTextColor || "white"
                  }
                  orientation="left"
                />
              </ButtonControlSt>
              <ButtonControlSt
                themeAccentColor={
                  wedding?.customTheme?.filledButtonBackgroundColor
                }
                onClick={() => {
                  setSkip((s) => s + 1);
                }}
                disabled={nextButtonDisabled}
              >
                <CaretIcon
                  color={
                    nextButtonDisabled
                      ? wedding?.customTheme?.accentColor ||
                        theme.colors.brandRed
                      : wedding?.customTheme?.filledButtonTextColor || "white"
                  }
                  orientation="right"
                />
              </ButtonControlSt>
            </ControlsSt>
          )}
        </HeaderWithControlsSt>
        <FactCardsContainerSt>
          {wedding?.stories.slice(skip, skip + 3).map((story) => (
            <FactCardSt key={story.id}>
              <FactImageWithCreditSt>
                <FactImageSt cloudinaryURL={story.image?.url} />
                <UITypography variant="body3">
                  {story.image?.caption}
                </UITypography>
              </FactImageWithCreditSt>
              <UITypography variant="body1">{story.title}</UITypography>
              <UITypography variant="body2" lineHeight="1.25rem">
                {story.description}
              </UITypography>
            </FactCardSt>
          ))}
        </FactCardsContainerSt>
      </FactsCarouselSt>
      <ImageShowcaseSt
        onClick={() => {
          setShowPhotoGallery(true);
        }}
      >
        <MainImageWithCreditSt>
          <MainImageSt cloudinaryURL={wedding?.stories[0]?.image?.url} />
          <UITypography variant="body3">
            {wedding?.stories[0]?.image?.caption}
          </UITypography>
        </MainImageWithCreditSt>
        <SecondaryImagesSt>
          <SecondaryImageWithCreditSt>
            <SecondaryImageSt cloudinaryURL={wedding?.stories[1]?.image?.url} />
            <UITypography variant="body3">
              {wedding?.stories[1]?.image?.caption}
            </UITypography>
          </SecondaryImageWithCreditSt>
          <SecondaryImageWithCreditSt>
            <SecondaryImageSt cloudinaryURL={wedding?.stories[2]?.image?.url} />
            <UITypography variant="body3">
              {wedding?.stories[2]?.image?.caption}
            </UITypography>
          </SecondaryImageWithCreditSt>
        </SecondaryImagesSt>
        <UISpacer variant="sm" />
        <ButtonSt>
          <UITypography variant="body1">Show All Photos</UITypography>
        </ButtonSt>
      </ImageShowcaseSt>
      <MobilePhotoGallery
        isOpen={showPhotoGallery}
        onClose={() => setShowPhotoGallery(false)}
        images={wedding?.stories.map((s) => s.image) || []}
      />
    </section>
  );
};

export default GuestPageOurStorySection;
