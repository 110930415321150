import React from "react";
import styled from "styled-components";

const ContainerSt = styled.div`
  text-align: left;

  ${({ theme }) => `${theme.breakpoints.md} {
    text-align: center;
  }`}
`;

interface SuperTitleStProps {
  themeAccentColor: string | undefined;
}

const SuperTitleSt = styled.h3<SuperTitleStProps>`
  color: ${({ theme, themeAccentColor }) =>
    themeAccentColor || theme.colors.brandRed};
  font-weight: 700;
  font-family: Poppins, "Montserrat";
  font-size: 1.0625rem;
  line-height: 0.5rem;
  margin: 0;

  ${({ theme }) => `${theme.breakpoints.md} {
    line-height: 2rem;
  }`}
`;

const TitleSt = styled.h2`
  margin: 0;
  color: ${({ theme }) => theme.colors.text01};
  font-size: 1.25rem;
  font-weight: 400;
  font-family: Poppins, "Montserrat";
  line-height: 3rem;
  padding-bottom: 10px;
  border-bottom: 1px solid rgb(239, 239, 239);

  ${({ theme }) => `${theme.breakpoints.md} {
    font-size: 2.1875rem;
    padding-bottom: unset;
    border-bottom: unset;
  }`}
`;

const GuestPageSectionHeader = ({
  superTitle,
  title,
  themeAccentColor,
}: {
  superTitle: string;
  title: string;
  themeAccentColor: string | undefined;
}) => {
  return (
    <ContainerSt>
      <SuperTitleSt themeAccentColor={themeAccentColor}>
        {superTitle}
      </SuperTitleSt>
      <TitleSt>{title}</TitleSt>
    </ContainerSt>
  );
};

export default GuestPageSectionHeader;
