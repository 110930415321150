import { useEffect, useRef, ReactNode } from 'react'
import Modal from 'react-modal'
import {
  enableBodyScroll,
  disableBodyScroll,
  clearAllBodyScrollLocks,
} from 'body-scroll-lock'
import { theme } from '../../../../utils/theme'

interface Props {
  isOpen: boolean
  isFullScreen?: boolean
  closeModal: () => void
  children: (scrollableTarget: any) => ReactNode
  outsideClickEnablesScroll?: boolean
}

const getCustomStyles = (isFullScreen: boolean | undefined): Modal.Styles => ({
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0, 
    backgroundColor: 'rgba(82, 82, 82, 0.75)',
    zIndex: 99,
  },
  content: {
    top: isFullScreen ? 0 : '50%',
    left: isFullScreen ? 0 : '50%',
    right: isFullScreen ? 0 : 'auto',
    bottom: isFullScreen ? 0 : 'auto',
    marginRight: isFullScreen ? 0 : '-50%',
    transform: isFullScreen ? 'none' : 'translate(-50%, -50%)',
    padding: 0,
    border: 'none',
    boxShadow: theme.boxShadows.shadow1,
    WebkitOverflowScrolling: 'touch',
  },
})

Modal.setAppElement('#__next')

const TTOModal = ({ isOpen, closeModal, children, isFullScreen, outsideClickEnablesScroll = true }: Props) => {
  const scrollableTarget = useRef<HTMLDivElement | any>(null)

  useEffect(() => {
    return () => {
      clearAllBodyScrollLocks()
    }
  }, [isOpen])

  const afterOpenModal = () => {
    scrollableTarget.current &&
      disableBodyScroll(scrollableTarget.current, {
        allowTouchMove: el => {
          while (el && el !== document.body) {
            if (el.getAttribute instanceof Function && (el.getAttribute('data-body-scroll-lock-ignore') !== null)) {
              return true
            }

            el = el.parentNode as any
          }
          return false
        },
      })
  }

  const onRequestClose = () => {
    outsideClickEnablesScroll && scrollableTarget.current && enableBodyScroll(scrollableTarget.current)
    closeModal()
  }

  return (
    <Modal
      isOpen={isOpen}
      onAfterOpen={afterOpenModal}
      onRequestClose={onRequestClose}
      style={getCustomStyles(isFullScreen)}
      contentLabel="Modal"
    >
      <div>{children(scrollableTarget)}</div>
    </Modal>
  )
}

export default TTOModal
