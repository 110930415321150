import React, { RefObject, useEffect, useMemo, useState } from "react";
import Select from "react-select";
import styled from "styled-components";
import { useCurrencyContext } from "../../../../../contexts/CurrencyContext";
import { useResponsive } from "../../../../../utils/hooks/useResponsive";
import { theme } from "../../../../../utils/theme";
import CaretIcon from "../../../../shared/atoms/Icons/CaretIcon/CaretIcon";
import { UISpacer } from "../../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import TTOModal from "../../../../shared/molecules/Modal/Modal";

interface LiProps {
  isActive: boolean;
}

const Li = styled.li<LiProps>`
  width: 100%;
  padding: 0 1rem;

  &:first-of-type {
    border-top: 1px solid ${({ theme }) => theme.colors.text01};
  }

  border-bottom: 1px solid ${({ theme }) => theme.colors.text01};

  a {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    white-space: nowrap;
    font-family: "Poppins";
    font-weight: 600;
    letter-spacing: 0.125rem;
    color: ${({ theme }) => theme.colors.text01};
    text-decoration: none;
    line-height: 2.5rem;
    font-size: 1.125rem;
    padding: 2rem 0;

    ${({ theme }) => `${theme.breakpoints.md} {
      font-size: 1rem;
      padding: 1rem 0;
      font-weight: 500;
    }`}
  }
`;

const ContentSt = styled.div`
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => `${theme.breakpoints.md} {
    width: 400px;
    height: unset;
  }`}
`;

const HeaderSt = styled.div`
  margin: 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const MenuSt = styled.menu`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-block-start: 0;
  margin-block-end: 0;
  margin-inline-start: 0;
  margin-inline-end: 0;
  padding-inline-start: 0;
  padding: 2rem 2rem 5rem;

  ul {
    width: 100%;
    list-style: none;
    margin-block-start: 0;
    margin-block-end: 0;
    padding-inline-start: 0;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    padding: 2rem 2rem 1rem;
  }`}
`;

const CloseButtonSt = styled.button`
  // position: absolute;
  // top: 2rem;
  // right: 1.5rem;

  &:hover {
    cursor: pointer;
  }
`;

const CloseIconSt = styled.img`
  height: 20px;
`;

const SelectContainerSt = styled.div`
  width: 160px;

  ${({ theme }) => `${theme.breakpoints.md} {
    width: 100px;
  }`}
`;

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  ourStorySectionRef: RefObject<HTMLDivElement>;
  theVenueSectionRef: RefObject<HTMLDivElement>;
  theBridalPartySectionRef: RefObject<HTMLDivElement>;
  theItinerarySectionRef: RefObject<HTMLDivElement>;
  rsvpSectionRef: RefObject<HTMLDivElement>;
  registrySectionRef: RefObject<HTMLDivElement>;
  faqsSectionRef: RefObject<HTMLDivElement>;
  hideRSVPModule: boolean;
  hideHotelModule: boolean;
  bridalPartySectionText: string | null
  hideFAQs: boolean;
}

const GuestPageMenu = ({
  isOpen,
  onCloseModal,
  ourStorySectionRef,
  theVenueSectionRef,
  theBridalPartySectionRef,
  theItinerarySectionRef,
  rsvpSectionRef,
  registrySectionRef,
  faqsSectionRef,
  hideRSVPModule,
  hideHotelModule,
  bridalPartySectionText,
  hideFAQs,
}: Props) => {
  const [scrollTop, setScrollTop] = useState(0);
  const { isTablet } = useResponsive();
  const { exchangeRates, selectedCurrency, setCurrency } = useCurrencyContext();

  const options = exchangeRates?.map((er) => ({
    value: er.currencyCode,
    label: er.currencyCode,
  }));

  const ourStorySectionScrollTop = useMemo(
    () => (ourStorySectionRef?.current?.offsetTop || 0) - 400,
    [ourStorySectionRef]
  );

  const theVenueSectionScrollTop = useMemo(
    () => (theVenueSectionRef?.current?.offsetTop || 0) - 400,
    [theVenueSectionRef]
  );

  const theBridalPartyScrollTop = useMemo(
    () => (theBridalPartySectionRef?.current?.offsetTop || 0) - 400,
    [theBridalPartySectionRef]
  );

  const theItinerarySectionScrollTop = useMemo(
    () => (theItinerarySectionRef?.current?.offsetTop || 0) - 400,
    [theItinerarySectionRef]
  );

  const rsvpSectionScrollTop = useMemo(
    () => (rsvpSectionRef?.current?.offsetTop || 0) - 400,
    [rsvpSectionRef]
  );

  const registrySectionScrollTop = useMemo(
    () => (registrySectionRef?.current?.offsetTop || 0) - 400,
    [registrySectionRef]
  );

  const faqsSectionScrollTop = useMemo(
    () => (faqsSectionRef?.current?.offsetTop || 0) - 400,
    [faqsSectionRef]
  );

 

  const sectionsTopAndBottom = useMemo(() => {
    const nextRefThatIsDefinedWithAPositiveOffsetTop = (
      currentRefIndex: number
    ): number => {
      const orderedSectionRefs = [
        ourStorySectionScrollTop,
        theVenueSectionScrollTop,
        theItinerarySectionScrollTop,
        theBridalPartyScrollTop,
        rsvpSectionScrollTop,
        registrySectionScrollTop,
        faqsSectionScrollTop,
      ];

      const orderedSectionRefsCopy = [...orderedSectionRefs];

      return (
        orderedSectionRefsCopy
          .slice(currentRefIndex + 1)
          .find((refScrollTop) => refScrollTop > 0) || faqsSectionScrollTop // Because always will be FAQ section
      );
    };

    return {
      ourStorySection: {
        top: ourStorySectionScrollTop,
        bottom: nextRefThatIsDefinedWithAPositiveOffsetTop(0),
      },
      theVenueSection: {
        top: theVenueSectionScrollTop,
        bottom: nextRefThatIsDefinedWithAPositiveOffsetTop(1),
      },
      theBridalPartySection: {
        top: theBridalPartyScrollTop,
        bottom: nextRefThatIsDefinedWithAPositiveOffsetTop(2),
      },
      theItinerarySection: {
        top: theItinerarySectionScrollTop,
        bottom: nextRefThatIsDefinedWithAPositiveOffsetTop(3),
      },
      rsvpSection: {
        top: rsvpSectionScrollTop,
        bottom: nextRefThatIsDefinedWithAPositiveOffsetTop(4),
      },
      registrySection: {
        top: registrySectionScrollTop,
        bottom: nextRefThatIsDefinedWithAPositiveOffsetTop(5),
      },
    };
  }, [ourStorySectionScrollTop, theVenueSectionScrollTop, theBridalPartyScrollTop, theItinerarySectionScrollTop, rsvpSectionScrollTop, registrySectionScrollTop, faqsSectionScrollTop]);

  const scrollToRef = (ref: RefObject<HTMLDivElement>) =>
    window?.scrollTo({
      left: 0,
      top: (ref?.current?.offsetTop || 0) - 150,
      behavior: "smooth",
    });

  useEffect(() => {
    const onScroll = (e: any) => {
      setScrollTop(e.target.documentElement.scrollTop);
    };
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <>
      <TTOModal
        isOpen={isOpen}
        closeModal={onCloseModal}
        isFullScreen={!isTablet}
      >
        {(scrollableTarget: any) => (
          <ContentSt ref={scrollableTarget}>
            <HeaderSt>
              <SelectContainerSt>
                <Select
                  styles={{
                    option: (provided: any) => ({
                      ...provided,
                      fontSize: 16,
                    }),
                    menuList: (provided: any, state: any) => ({
                      ...provided,
                      maxHeight: isTablet ? "300px" : "unset",
                      paddingBottom: isTablet ? "4px" : "unset",
                      paddingTop: isTablet ? "4px" : "unset",
                      position: isTablet ? "relative" : "fixed",
                      backgroundColor: theme.colors.white,
                      ...(isTablet
                        ? {}
                        : {
                            top: 0,
                            left: 0,
                            bottom: 0,
                            right: 0,
                            height: "100%",
                          }),
                    }),
                  }}
                  options={options}
                  value={{ value: selectedCurrency, label: selectedCurrency }}
                  onChange={(value: any) => {
                    setCurrency(value.value);
                  }}
                  isSearchable={false}
                />
              </SelectContainerSt>
              <CloseButtonSt onClick={onCloseModal}>
                <CloseIconSt src="/images/cross.svg" alt="Close Icon" />
              </CloseButtonSt>
            </HeaderSt>
            <MenuSt>
              <ul>
                {ourStorySectionRef?.current && (
                  <Li
                    isActive={
                      scrollTop >= sectionsTopAndBottom.ourStorySection.top &&
                      scrollTop < sectionsTopAndBottom.ourStorySection.bottom
                    }
                  >
                    <a
                      href="#ourStory"
                      onClick={(e) => {
                        e.preventDefault();
                        onCloseModal();
                        scrollToRef(ourStorySectionRef);
                      }}
                    >
                      OUR STORY
                      <CaretIcon
                        color={theme.colors.text01}
                        orientation="right"
                      />
                    </a>
                  </Li>
                )}
                {theVenueSectionRef?.current && (
                  <Li
                    isActive={
                      scrollTop >= sectionsTopAndBottom.theVenueSection.top &&
                      scrollTop < sectionsTopAndBottom.theVenueSection.bottom
                    }
                  >
                    <a
                      href="#theVenue"
                      onClick={(e) => {
                        e.preventDefault();
                        onCloseModal();
                        scrollToRef(theVenueSectionRef);
                      }}
                    >
                      THE VENUE
                      <CaretIcon
                        color={theme.colors.text01}
                        orientation="right"
                      />
                    </a>
                  </Li>
                )}
                {theBridalPartySectionRef?.current && (
                  <Li
                    isActive={
                      scrollTop >=
                        sectionsTopAndBottom.theBridalPartySection.top &&
                      scrollTop <
                        sectionsTopAndBottom.theBridalPartySection.bottom
                    }
                  >
                    <a
                      href="#theBridalParty"
                      onClick={(e) => {
                        e.preventDefault();
                        onCloseModal();
                        scrollToRef(theBridalPartySectionRef);
                      }}
                    >
                      {bridalPartySectionText?.toUpperCase() || 'THE BRIDAL PARTY'}
                      <CaretIcon
                        color={theme.colors.text01}
                        orientation="right"
                      />
                    </a>
                  </Li>
                )}
                {theItinerarySectionRef?.current && (
                  <Li
                    isActive={
                      scrollTop >=
                        sectionsTopAndBottom.theItinerarySection.top &&
                      scrollTop <
                        sectionsTopAndBottom.theItinerarySection.bottom
                    }
                  >
                    <a
                      href="#theItinerary"
                      onClick={(e) => {
                        e.preventDefault();
                        onCloseModal();
                        scrollToRef(theItinerarySectionRef);
                      }}
                    >
                      THE ITINERARY
                      <CaretIcon
                        color={theme.colors.text01}
                        orientation="right"
                      />
                    </a>
                  </Li>
                )}
                {rsvpSectionRef?.current && (
                  <Li
                    isActive={
                      scrollTop >= sectionsTopAndBottom.rsvpSection.top &&
                      scrollTop < sectionsTopAndBottom.rsvpSection.bottom
                    }
                  >
                    <a
                      href="#rsvp"
                      onClick={(e) => {
                        e.preventDefault();
                        onCloseModal();
                        scrollToRef(rsvpSectionRef);
                      }}
                    >
                      {hideRSVPModule && !hideHotelModule && 'BOOK TRIP'}
                      {!hideRSVPModule && hideHotelModule && 'RSVP'}
                      {!hideRSVPModule && !hideHotelModule && "RSVP & BOOK TRIP"}

                      <CaretIcon
                        color={theme.colors.text01}
                        orientation="right"
                      />
                    </a>
                  </Li>
                )}
                {registrySectionRef?.current && (
                  <Li
                    isActive={
                      scrollTop >= sectionsTopAndBottom.registrySection.top &&
                      scrollTop < sectionsTopAndBottom.registrySection.bottom
                    }
                  >
                    <a
                      href="#registry"
                      onClick={(e) => {
                        e.preventDefault();
                        onCloseModal();
                        scrollToRef(registrySectionRef);
                      }}
                    >
                      GIFT REGISTRY
                      <CaretIcon
                        color={theme.colors.text01}
                        orientation="right"
                      />
                    </a>
                  </Li>
                )}
                {faqsSectionRef?.current && !hideFAQs && (
                  <Li isActive={scrollTop >= faqsSectionScrollTop}>
                    <a
                      href="#faqs"
                      onClick={(e) => {
                        e.preventDefault();
                        onCloseModal();
                        scrollToRef(faqsSectionRef);
                      }}
                    >
                      FAQS
                      <CaretIcon
                        color={theme.colors.text01}
                        orientation="right"
                      />
                    </a>
                  </Li>
                )}
              </ul>
              <UISpacer variant="xl" />
            </MenuSt>
          </ContentSt>
        )}
      </TTOModal>
    </>
  );
};

export default GuestPageMenu;
