import React from "react";
import styled from "styled-components";
import dynamic from "next/dynamic";
import { GetWeddingContract } from "@ttoengineering/delphi";
import GuestPageSectionHeader from "../GuestPageSectionHeader/GuestPageSectionHeader";
import { UISpacer } from "../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer";
import { UITypography } from "../../../shared/atoms/UIComponents/UITypography/UITypography";

const MapWithNoSSR = dynamic(
  () => import("./VenueMap/VenueMap"),
  {
    ssr: false,
  }
);

const AirportInfoContainerSt = styled.div`
  display: block;
  min-height: 240px;

  ${({ theme }) => `${theme.breakpoints.md} {
    display: flex;
  }`}
`;

const MapSt = styled.div`
  width: 100%;
  height: 240px;

  ${({ theme }) => `${theme.breakpoints.md} {
    flex-basis: 50%;
    height: unset;
  }`}
`;

const AirportDetailsSt = styled.div`
  width: 100%;
  margin-top: 2rem;
  text-align: left;

  h4 {
    margin-bottom: 1rem;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    margin-top: 0;
    flex-basis: 50%;
    padding-left: 40px;

    h4 {
      border-bottom: 1px solid #333333;
      margin-bottom: 2.5rem;
    }
  }`}
`;

const AirportCardSt = styled.div`
  display: flex;
`;

const AirportIconSt = styled.img`
  margin-right: 20px;
`;

const TextContainerSt = styled.div`
  text-align: left;
`;

interface Props {
  wedding: GetWeddingContract;
}

const GuestPageGettingHereSection = ({ wedding }: Props) => {
  const theLocationDescriptionLines =
    wedding?.theLocationDescription?.split("|");
  return (
    <section>
      <GuestPageSectionHeader
        superTitle={wedding?.theLocationSupertitle}
        title={wedding?.theLocationTitle}
        themeAccentColor={wedding?.customTheme?.textColor}
      />
      <UISpacer />
      <TextContainerSt>
        {theLocationDescriptionLines?.map((line, index) => {
          return (
            <div key={index}>
              <UITypography variant="body2">{line}</UITypography>
              <UISpacer variant="sm" />
            </div>
          );
        })}
      </TextContainerSt>
      <UISpacer />
      <AirportInfoContainerSt>
        <MapSt>
          <MapWithNoSSR
            venueCoords={{
              latitude: wedding?.venueLatitude,
              longitude: wedding?.venueLongitude,
            }}
            airportCoords={wedding?.nearbyAirports}
          />
        </MapSt>
        <AirportDetailsSt>
          <UITypography variant="body1" component="h4">
            Nearby Airports
          </UITypography>
          {wedding?.nearbyAirports.map((a) => (
            <span key={a.latitude}>
              <AirportCardSt>
                <AirportIconSt src="/images/plane.svg" />
                <div>
                  <UITypography variant="body2" lineHeight="1.5rem">
                    {a.name}{" "}
                    {a.distanceFromVenueKM && `(${a.distanceFromVenueKM}km)`}
                  </UITypography>
                  <UITypography variant="body2Italic" lineHeight="1.5rem">
                    {a.travelToVenue}
                  </UITypography>
                </div>
              </AirportCardSt>
              <UISpacer variant="sm" />
            </span>
          ))}
        </AirportDetailsSt>
      </AirportInfoContainerSt>
    </section>
  );
};

export default GuestPageGettingHereSection;
