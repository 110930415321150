import React, { useState } from 'react'
import Linkify from 'react-linkify'
import styled from 'styled-components'
import Collapsible from 'react-collapsible'
import { GetWeddingContract, WeddingFAQ } from '@ttoengineering/delphi'
import { UITypography } from '../../../shared/atoms/UIComponents/UITypography/UITypography'
import { theme } from '../../../../utils/theme'
import { UISpacer } from '../../../shared/atoms/UIComponents/NewUISpacer/NewUISpacer'
import GuestPageSectionHeader from '../GuestPageSectionHeader/GuestPageSectionHeader'

const FAQAccordionSt = styled.div``

const AccordionHeaderSt = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.25rem 20px 1.25rem 0;
  border-bottom: 1px solid ${({ theme }) => theme.colors.lightGrey};
  text-align: left;

  &:hover {
    cursor: pointer;
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    padding: 1.25rem 20px 1.25rem 40px;
    border-top: 1px solid ${theme.colors.lightGrey};
    border-bottom: none;
  }`}
`

const PlusSt = styled.span`
  font-size: 24px;
  margin-left: 40px;
  font-family: 'Poppins';
  font-weight: 700;
  color: ${({ theme }) => theme.colors.text02};
`

interface AccordionDrawerStProps {
  linkColor: string
}

const AccordionDrawerSt = styled.div<AccordionDrawerStProps>`
  padding: 1rem 20px 0 0;
  text-align: left;

  p {
    word-break: break-word;
  }

  a {
    color: ${({ linkColor }) => linkColor};
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    padding: 1rem 40px 20px;
  }`}
`

interface AccordionHeaderProps {
  faq: WeddingFAQ
  isOpen: boolean
  themeTextColor: string | undefined
}

const AccordionHeader = ({
  faq,
  isOpen,
  themeTextColor,
}: AccordionHeaderProps) => {
  const faqQuestionLines = faq?.question?.split('|')

  return (
    <AccordionHeaderSt>
      <div>
        {faqQuestionLines?.map((line, index) => {
          return (
            <div key={index}>
              <UITypography
                variant="body1"
                color={
                  isOpen
                    ? themeTextColor || theme.colors.brandRed
                    : theme.colors.text02
                }
              >
                {line}
              </UITypography>
            </div>
          )
        })}
      </div>
      <PlusSt>+</PlusSt>
    </AccordionHeaderSt>
  )
}

interface AccordionDrawerProps {
  faq: WeddingFAQ
  themeTextColor: string | undefined
}

const AccordionDrawer = ({ faq, themeTextColor }: AccordionDrawerProps) => {
  const lines = faq.answer.split('|')
  return (
    <AccordionDrawerSt linkColor={themeTextColor || theme.colors.brandRed}>
      {lines.map((line, index) => (
        <Linkify key={index}>
          <UITypography variant={'body2'} >
            {line}
          </UITypography>
          <UISpacer variant="sm" />
        </Linkify>
      ))}
    </AccordionDrawerSt>
  )
}

interface Props {
  wedding: GetWeddingContract
}

const GuestPageFAQSection = ({ wedding }: Props) => {
  const [openFAQIds, setOpenFAQIds] = useState<number[]>([])

  return (
    <section>
      <GuestPageSectionHeader
        superTitle={wedding?.hereForYouSupertitle}
        title={wedding?.hereForYouTitle}
        themeAccentColor={wedding?.customTheme?.textColor}
      />
      <UISpacer />
      <FAQAccordionSt>
        {wedding?.faqs.map(faq => (
          <Collapsible
            onOpen={() => {
              setOpenFAQIds(currentState => [...currentState, faq.id])
            }}
            onClose={() => {
              setOpenFAQIds(currentState =>
                [...currentState].filter(id => id !== faq.id)
              )
            }}
            trigger={
              <AccordionHeader
                faq={faq}
                isOpen={openFAQIds.includes(faq.id)}
                themeTextColor={wedding?.customTheme?.textColor}
              />
            }
            transitionTime={200}
            key={faq.id}
          >
            <AccordionDrawer
              faq={faq}
              themeTextColor={wedding?.customTheme?.textColor}
            />
          </Collapsible>
        ))}
      </FAQAccordionSt>
    </section>
  )
}

export default GuestPageFAQSection
