import { GetWeddingContract } from "@ttoengineering/delphi";
import { RefObject } from "react";
import styled from "styled-components";
import { theme } from "../../../../utils/theme";
import { UITypography } from "../../../shared/atoms/UIComponents/UITypography/UITypography";

const ContainerSt = styled.div`
  width: 100%;
  position: fixed;
  z-index: 99;
  bottom: 0px;
  background-color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => `${theme.breakpoints.md} {
    position: sticky;
    bottom: unset;
    top: 0px;
    padding: 20px 40px 0;
    max-width: 1820px;
    margin: 0 auto;
  }`}

  ${({ theme }) => `${theme.breakpoints.fiveXl} {
    max-width: 2560px;
  }`}
`;

const CardSt = styled.div`
  height: 88px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  z-index: 9;
  padding: 0 14px;

  > p {
    padding: 0 1rem 0 0;
    font-size: 0.75rem;
    line-height: 1.25rem;
  }

  span {
    font-weight: 700;
    display: block;
  }

  ${({ theme }) => `${theme.breakpoints.sm} {
    > p {
      font-size: 0.9375rem;
      line-height: unset;
    }
  }`}

  ${({ theme }) => `${theme.breakpoints.md} {
    height: 60px;
    padding: 0;

    > p {
      font-size: unset;
      padding: 0 2.5rem;
    }

    br {
      display: none;
    }

    span {
      font-weight: unset;
      display: unset;
      margin-right: 0;
    }
  }`}

  ${({ theme }) => `${theme.breakpoints.fiveXl} {
      height: 90px;
    }`}
`;

interface ButtonStProps {
  backgroundColor: string | undefined;
  textColor: string | undefined;
}

const ButtonSt = styled.button<ButtonStProps>`
  -webkit-font-smoothing: antialiased;
  width: 140px;
  height: 60px;
  background: ${({ backgroundColor }) => backgroundColor || "rgb(235,22,89)"};
  background: ${({ backgroundColor }) =>
    backgroundColor ||
    "linear-gradient(90deg, rgba(235,22,89,1) 0%, rgba(255,120,3,1) 100%)"};
  font-family: "Poppins";
  font-weight: 500;
  font-size: 1.0625rem;
  letter-spacing: 0.0625rem;
  color: ${({ theme, textColor }) => textColor || theme.colors.white};
  border-radius: 4px;

  :hover {
    cursor: pointer;
    background-color: ${({ theme, backgroundColor }) =>
      backgroundColor || theme.colors.darkRed};
  }

  ${({ theme }) => `${theme.breakpoints.md} {
    width: 260px;
    height: 100%;
    border-radius: unset;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
  }`}

  ${({ theme }) => `${theme.breakpoints.fiveXl} {
    width: 320px
  }`}
`;

interface Props {
  wedding: GetWeddingContract;
  rsvpSectionRef: RefObject<HTMLDivElement>;
  hideRSVPModule: boolean;
  hideHotelModule: boolean;
}

const GuestPageCTABar = ({
  wedding,
  rsvpSectionRef,
  hideRSVPModule,
  hideHotelModule,
}: Props) => {
  return (
    <ContainerSt>
      <CardSt>
        <UITypography
          variant="body1"
          color={wedding?.customTheme?.textColor || theme.colors.brandRed}
          lineHeight="1.5rem"
          fontWeight={500}
        >
          {hideRSVPModule && !hideHotelModule && (
            <>
              <span>You’re Invited!</span> {`Let's Book Your Trip.`}
            </>
          )}
          {!hideRSVPModule && hideHotelModule && (
            <>
              <span>You’re Invited!</span> Confirm Attendance
            </>
          )}
          {!hideRSVPModule && !hideHotelModule && (
            <>
              <span>You’re Invited!</span> Confirm Attendance <br /> & Book
              Trip.
            </>
          )}
        </UITypography>
        <ButtonSt
          backgroundColor={wedding?.customTheme?.filledButtonBackgroundColor}
          textColor={wedding?.customTheme?.filledButtonTextColor}
          onClick={() => {
            window.scrollTo({
              left: 0,
              top: (rsvpSectionRef?.current?.offsetTop || 0) - 150,
              behavior: "smooth",
            });
          }}
        >
          {`Let's Go`}
        </ButtonSt>
      </CardSt>
    </ContainerSt>
  );
};

export default GuestPageCTABar;
